import type { Dictionary } from "@reduxjs/toolkit";
import { getDisplayNameForContact } from "@somewear/asset";
import { selectIsFeatureOn } from "@somewear/feature";
import type { Contact, IContactForDisplay, LoadingStateType } from "@somewear/model";
import { someFeatures } from "@somewear/model";
import { getColorForAsset } from "@somewear/workspace";
import { compact, get } from "lodash";
import type { Action } from "redux";
import { createSelector } from "reselect";

import type { RootState } from "./rootReducer";

export const selectAppState = (state: RootState) => state.app;

export const selectLastPingSent = createSelector([selectAppState], (state) => {
	return state.lastPingSent;
});

export const selectLastPongReceived = createSelector([selectAppState], (state) => {
	return state.lastPongReceived;
});

// this is used to pass an attribute as a selector into create selector
export function selectEcho<T>(state: RootState, input: T): T {
	return input;
}

export const createLoadingSelector = (actions: Action<string>[]) => (state: LoadingStateType) => {
	const actionNames = compact(
		actions.map((action) => {
			const matches = /(.*)(request|success|error|fulfilled|rejected)/.exec(
				action.type.toLowerCase(),
			);
			if (!matches) return undefined;
			const [, requestName] = matches;
			return requestName;
		}),
	);
	return actionNames.some((action) => get(state, action));
};

/*
export const createErrorSelector = (actions: Action<string>[]) => (state: ErrorStateType) => {
	let actionNames = _.compact(
		actions.map((action) => {
			const matches = /(.*)(Request|Success|Error)/.exec(action.type);
			if (!matches) return undefined;
			const [, requestName] = matches;
			return requestName;
		})
	);
	return _.compact(actionNames.map((action) => _.get(state, action))).firstOrUndefined();
};
 */

export const selectUseMgrs = (state: RootState) => {
	return state.app.useMgrs;
};

export const selectIsInitialLoadComplete = (state: RootState) => state.app.isInitialLoadComplete;

export const selectActiveAuthId = (state: RootState) => {
	return state.app.activeAuthId;
};

export const selectAppUser = (state: RootState) => state.app.user;

export const selectIsUserStaff = createSelector(
	[selectAppUser, (state) => selectIsFeatureOn(state, someFeatures.ENABLE_STAFF_FEATURES)],
	(user, isStaffFeatureFlagOn) => {
		return (
			user?.email?.endsWith("@somewearlabs.com") ||
			user?.email?.endsWith("@somewear.co") ||
			isStaffFeatureFlagOn
		);
	},
);

export const selectRefreshRequiredMessage = (state: RootState) => state.app.requireRefreshMessage;

export const selectIsSidebarVisible = (state: RootState) => state.app.sidebarVisible;

export const selectOrganizationLicenseSummary = (state: RootState) => state.app.licenseSummary;

export const selectLicenseCapabilities = createSelector(
	[selectOrganizationLicenseSummary],
	(summary) => summary?.capabilitiesList ?? [],
);

export const workspaceInfoSelector = (state: RootState) => {
	const workspaceInfoContact: Dictionary<IContactForDisplay> = {};
	Object.values(state.app.workspaceInfo).forEach((info: Contact) => {
		workspaceInfoContact[info.id] = {
			...info,
			displayName: getDisplayNameForContact(info, false),
			color: getColorForAsset(info),
		};
	});
	return workspaceInfoContact;
};

/*
export const selectIsWorkspaceLoaded = (state: RootState) => {
	return state.app.workspaceLoaded;
};
*/
