import * as amplitude from "@amplitude/analytics-browser";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
	GetAssetsResponse,
	NotificationPayload,
	SignInResponse,
	UpdateStyleSettingsRequest,
	UpdateUserPasswordResponse,
	UserIdentityResponse,
} from "@somewear/api";
import {
	DeleteRouteRequest,
	DeviceEventResponse,
	DeviceRecord,
	FileMetadata,
	GeofenceTraversal,
	GetOrganizationsResponse,
	GetSelfAccountsResponse,
	GetWorkspacesResponse,
	HealthActivityResponse,
	IdentityRecord,
	LicenseUsageNotification,
	LocationResponse,
	MessageResponse,
	NotificationType,
	OrganizationMemberResponse,
	OrganizationRole,
	PongResponse,
	RouteResponse,
	SignInRequest,
	SosEventResponse,
	UpdateWorkspaceAccessResponse,
	UserResponse,
	WorkspaceAccess,
	WorkspaceResponse,
} from "@somewear/api";
import { IntegrationConnection } from "@somewear/api/src/proto/integration_proto_pb";
import { apiKeysActions } from "@somewear/api-keys";
import {
	assetActions,
	emitActiveUserAccountDeleted,
	emitAddUserAccountFromServer,
	emitAssetAccountsDeleted,
	emitIdentityChangeFromServer,
	emitUserAccountChangeFromServer,
	identityActions,
	selectActiveIdentity,
	selectAllWorkspaceAssets,
	selectIdentityById,
	selectSelfAccounts,
	selectUnarchivedWorkspaceAssets,
	selectWorkspaceAssetById,
} from "@somewear/asset";
import type { ISomewearAuthService, IUser, UpdatePasswordArgs } from "@somewear/auth";
import {
	Api,
	AuthController,
	selectActiveIdentityId,
	selectActiveOrganizationId,
	selectActiveUserAccountId,
	selectActiveWorkspaceId,
	setActiveIdentityId,
	setActiveOrganizationId,
	setActiveUserAccountId,
	setActiveWorkspaceId,
	setUser,
	signedOut,
	UserSource,
} from "@somewear/auth";
import { apiHealthActivityRequest, apiHealthActivitySuccess } from "@somewear/biometric";
import { apiDeviceRecordUpdate, deviceActions } from "@somewear/device";
import { featureActions } from "@somewear/feature";
import { emitFileReceivedFromStream } from "@somewear/files";
import { grpc, isStreamLifecycleEvent, someGrpc } from "@somewear/grpc";
import { mapLayersSlice } from "@somewear/layers";
import {
	conversationActions,
	emitMessageReceivedFromStream,
	fetchTrackingFilters,
	fetchTrackingSettings,
	fetchWorkspaceFilters,
	getReadTimestamps,
	messageActions,
	setFilteredWorkspaceId,
	WORKSPACE_ID_ALL,
} from "@somewear/messaging";
import type { ActionSetEpic, IIdentity, IMessage } from "@somewear/model";
import {
	actionSetEpicHandlerBuilder,
	createActionSetEpicHandler,
	haltAll,
	resetState,
	Sentry,
	timestampFromMoment,
	timestampToMoment,
	transformerEpicBuilder,
} from "@somewear/model";
import { notificationsSlice } from "@somewear/notification";
import {
	organizationActions,
	organizationDeviceActions,
	organizationMemberActions,
	organizationsSlice,
	selectHasActiveOrganization,
} from "@somewear/organization";
import { sensorsActions } from "@somewear/sensors";
import { apiDeviceEventSuccess } from "@somewear/settings";
import { selectShapeById, shapeActions } from "@somewear/shapes";
import {
	apiSosEventsRequest,
	apiSosEventsSuccess,
	selectAllSosEventSessionIds,
} from "@somewear/sos";
import {
	apiSubscriptionsError,
	apiSubscriptionsSuccess,
	subscriptionsSlice,
} from "@somewear/subscription";
import { waypointActions, waypointsSlice } from "@somewear/waypoint";
import type { IWorkspace } from "@somewear/workspace";
import {
	clearWorkspace,
	emitWorkspaceChangeFromServer,
	emitWorkspacesReceived,
	PERSONAL_WORKSPACE_ID,
	selectHasTeamWorkspaces,
	selectTeamWorkspaces,
	selectWorkspaceById,
	updateActiveWorkspaceId,
	updateUserAccount,
	updateWorkspaceAccess,
	workspaceActions,
	WorkspaceUtil,
} from "@somewear/workspace";
import { backoffRetry } from "@web/common/ObservableUtils";
import { trackingRouteActions } from "@web/tracking/routes/trackingRouteActions";
import { getLocationsFulfilled, initMapView } from "@web/tracking/trackingActions";
import { resetDateFilter, resetWorkspaceFilters, sosDismissed } from "@web/tracking/trackingSlice";
import { StatusCode } from "grpc-web";
import { cloneDeep } from "lodash";
import moment from "moment";
import type { Action, AnyAction } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { forkJoin, from, of, throwError } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { v4 as uuid } from "uuid";

import Config from "../config/Config";
import { selectSelectedConversation } from "../messaging/messagingSelectors";
import { appActions } from "./appActions";
import { selectLastPingSent, selectLastPongReceived } from "./appSelectors";
import {
	apiNotificationStreamClose,
	apiNotificationStreamDisconnected,
	apiNotificationStreamOpen,
	noOp,
	refreshDetailedAppData,
	refreshInitialAppData,
	requireRefresh,
	setActiveAuthId,
	setIsInitialLoadComplete,
	unauthorizedError,
	updateActiveOrganizationId,
	updateLicenseUsage,
} from "./appSlice";
import type { RootState } from "./rootReducer";

const signedOutEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(signedOut.type),
		map(() => haltAll()),
	);

const sendPingEpic = actionSetEpicHandlerBuilder(appActions.sendPing, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.sendPing, payload.data),
);

/*
Get the users subscriptions and dispatch the action to set it on the store
 */
const apiSubscriptionsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(subscriptionsSlice.actions.apiSubscriptionsRequest.type),
		switchMap(() =>
			Api.getSubscriptions().pipe(
				map((subscriptions) =>
					apiSubscriptionsSuccess(subscriptions.toObject().subscriptionsList),
				),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiSubscriptionsError(error))),
			),
		),
	);

function processNotification(notification: NotificationPayload, state: RootState): AnyAction[] {
	try {
		console.warn(`Received notification ${notification.getType()}`);

		switch (notification.getType()) {
			case NotificationType.NEWMESSAGENOTIFICATION: {
				const message = MessageResponse.deserializeBinary(notification.getData_asU8());
				console.log("did receive message over grpc");
				if (message.getConversationId().isNotEmpty()) {
					if (
						state.conversations.ids.includes(message.getConversationId()) ||
						state.contacts.ids.includes(message.getSenderId())
					) {
						return [emitMessageReceivedFromStream(message.toObject())];
					}
				} else if (message.getWorkspaceId().isNotEmpty()) {
					return [emitMessageReceivedFromStream(message.toObject())];
				} else {
					console.error("the message did not have a conversation or a workspace");
				}
				break;
			}

			case NotificationType.SOSEVENTNOTIFICATION: {
				const sosEvent = SosEventResponse.deserializeBinary(notification.getData_asU8());
				console.log("did receive sos over grpc", sosEvent.toObject());
				const location = new LocationResponse();
				location.setId(uuid());
				location.setCoordinate(sosEvent.getCoordinate());
				location.setUserId(sosEvent.getUserId());
				location.setTimestamp(sosEvent.getTimestamp());

				const message = new MessageResponse();
				message.setId(sosEvent.getId());
				message.setCoordinate(sosEvent.getCoordinate());
				message.setSenderId(sosEvent.getUserId());
				message.setWorkspaceId(sosEvent.getWorkspaceId());
				message.setSosSessionId(sosEvent.getSessionId());
				message.setOutgoing(false);
				message.setTimestamp(sosEvent.getTimestamp());
				message.setSosEventType(sosEvent.getType());

				const actions: AnyAction[] = [
					apiSosEventsSuccess([sosEvent.toObject()]),
					getLocationsFulfilled([location.toObject()]),
					emitMessageReceivedFromStream(message.toObject()),
				];

				/*if (!(sosEvent.getSessionId() in state.app.sosEvents)) {
					actions.push(sosDismissed(false));
				}*/

				if (!selectAllSosEventSessionIds(state).includes(sosEvent.getSessionId())) {
					actions.push(sosDismissed(false));
				}

				return actions;
			}

			case NotificationType.NEWHEALTHACTIVITYNOTIFICATION: {
				const response = HealthActivityResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive health activity over grpc");

				const activeUserAccountId = selectActiveUserAccountId(state);

				if (activeUserAccountId === undefined) return [];

				const activity = response.toObject();

				if (activity.owner?.id !== undefined) {
					return [
						apiHealthActivitySuccess([
							{
								...activity,
								ownerId: activity.owner.id!,
							},
						]),
					];
				} else {
					return [
						apiHealthActivitySuccess([
							{
								...activity,
								ownerId: activeUserAccountId,
							},
						]),
					];
				}
			}

			case NotificationType.UPDATEUSERACCOUNTNOTIFICATION: {
				const userAccount: UserResponse = UserResponse.deserializeBinary(
					notification.getData_asU8(),
				);

				const priorUserAccount = selectWorkspaceAssetById(state, userAccount.getId());

				const actionsOut: AnyAction[] = [];

				const archivedUserWasActivated =
					priorUserAccount?.status !== UserResponse.AccountStatus.ACTIVE &&
					userAccount.getStatus() === UserResponse.AccountStatus.ACTIVE;

				const activeUserWasUpdated =
					selectActiveIdentityId(state) === userAccount.getIdentityId();

				if (archivedUserWasActivated || activeUserWasUpdated) {
					actionsOut.push(updateUserAccount());
				}

				console.log("did receive user account update over grpc");

				actionsOut.push(emitUserAccountChangeFromServer(userAccount.toObject()));
				return actionsOut;
			}

			case NotificationType.NEWUSERACCOUNTNOTIFICATION: {
				const userAccount: UserResponse = UserResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive new user account over grpc");
				const actions: AnyAction[] = [];

				actions.push(updateUserAccount());
				actions.push(emitAddUserAccountFromServer(userAccount.toObject()));
				console.log("emitted new user account from server", userAccount.toObject());
				return actions;
			}

			case NotificationType.UPDATEIDENTITYNOTIFICATION: {
				const userIdentity: IdentityRecord.AsObject = IdentityRecord.deserializeBinary(
					notification.getData_asU8(),
				).toObject();
				console.log("did receive user identity update over grpc");

				const matchingAccounts = selectUnarchivedWorkspaceAssets(state)
					.filter((account) => account.identityId === userIdentity.id)
					.map((asset) => {
						const _account = cloneDeep(asset);
						_account.fullname = userIdentity.fullName;
						_account.styleSettings = userIdentity.styleSettings;
						return _account;
					});

				const accountActions = matchingAccounts.map((asset) =>
					emitUserAccountChangeFromServer(asset),
				);

				const actions = ([] as AnyAction[]).concat(accountActions);

				const matchingIdentity = selectIdentityById(state, userIdentity.id);
				if (matchingIdentity !== undefined) {
					const updatedIdentity: IIdentity = {
						...matchingIdentity,
						...userIdentity,
					};
					actions.push(emitIdentityChangeFromServer(updatedIdentity));
				} else {
					actions.push(emitIdentityChangeFromServer(userIdentity));
				}

				return actions;
			}

			case NotificationType.UPDATEDEVICENOTIFICATION: {
				const deviceRecord: DeviceRecord = DeviceRecord.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive device update over grpc");
				const actions: AnyAction[] = [];
				const asset = selectWorkspaceAssetById(state, deviceRecord.getUserAccountId());
				actions.push(
					apiDeviceRecordUpdate({ record: deviceRecord.toObject(), asset: asset }),
				);
				return actions;
			}

			case NotificationType.NEWDEVICESETTINGSEVENTNOTIFICATION: {
				const event: DeviceEventResponse = DeviceEventResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.warn("did receive device settings over grpc", event.toObject());
				const actions: AnyAction[] = [];
				actions.push(apiDeviceEventSuccess([event.toObject()]));
				return actions;
			}

			/*case NotificationType.UPDATEUSERLOCATIONSNOTIFICATION: {
				let locationResponse: LocationResponseList = LocationResponseList.deserializeBinary(
					notification.getData_asU8()
				);
				console.log("did receive location update over grpc");
				let actions: AnyAction[] = [];
				actions.push(apiLocationsSuccess(locationResponse.toObject().locationsList));
				return actions;
			}*/

			case NotificationType.NEWTRACKINGLOCATIONNOTIFICATION: {
				const locationResponse: LocationResponse = LocationResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive location update over grpc");
				return [getLocationsFulfilled([locationResponse.toObject()])];
			}

			case NotificationType.ROUTEDELETEDNOTIFICATION: {
				const deleteRoute: DeleteRouteRequest = DeleteRouteRequest.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive delete route over grpc");
				return [waypointsSlice.actions.removeWaypoint(deleteRoute.toObject())];
			}

			case NotificationType.NEWWAYPOINTNOTIFICATION: {
				const routeResponse: RouteResponse = RouteResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive new waypoint over grpc");
				return [waypointsSlice.actions.addWaypoint(routeResponse.toObject())];
			}

			case NotificationType.WAYPOINTUPDATEDNOTIFICATION: {
				const routeResponse: RouteResponse = RouteResponse.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive new waypoint over grpc");
				return [waypointsSlice.actions.updateWaypoint(routeResponse.toObject())];
			}

			case NotificationType.ARCHIVEWORKSPACENOTIFICATION: {
				const response: UpdateWorkspaceAccessResponse =
					UpdateWorkspaceAccessResponse.deserializeBinary(notification.getData_asU8());
				return [updateWorkspaceAccess(response.toObject())];
			}

			case NotificationType.ORGANIZATIONMEMBERSHIPCHANGENOTIFICATION: {
				const response: OrganizationMemberResponse =
					OrganizationMemberResponse.deserializeBinary(notification.getData_asU8());
				if (response.getOrganizationId() !== selectActiveOrganizationId(state)) {
					console.log(
						"did receive org membership change over grpc, but not for the active org",
					);
					return [];
				}
				console.log("did receive org membership change over grpc");

				const actionsOut: AnyAction[] = [];

				// check to see if the active user has been removed from the org
				if (
					selectActiveIdentityId(state) === response.getIdentity()?.getId() &&
					response.getRole() === OrganizationRole.ORGANIZATIONROLENONE
				) {
					actionsOut.push(
						requireRefresh("You have been removed from one of your organizations."),
					);
				}

				actionsOut.push(
					organizationMemberActions.membershipChanged.fulfilled({
						requestId: "",
						data: response.toObject(),
					}),
				);

				return actionsOut;
			}

			case NotificationType.UPDATEWORKSPACENOTIFICATION: {
				const response = WorkspaceResponse.deserializeBinary(notification.getData_asU8());

				return [emitWorkspaceChangeFromServer(response.toObject())];
			}

			case NotificationType.LICENSEUSAGECHANGENOTIFICATION: {
				const response = LicenseUsageNotification.deserializeBinary(
					notification.getData_asU8(),
				);
				return [updateLicenseUsage(response.toObject())];
			}

			case NotificationType.GEOFENCETRAVERSALNOTIFICATION: {
				const response = GeofenceTraversal.deserializeBinary(notification.getData_asU8());
				console.log(`GEOFENCE TRAVERSAL NOTIFICATION`, response.toObject());

				const shape = selectShapeById(state, response.getShapeId());
				if (shape === undefined) return [];

				const location = response.getTraversalLocation();
				if (location === undefined) return [];
				const coordinate = location.getCoordinate();
				const timestamp = location.getTimestamp();

				const message = new MessageResponse();
				message.setId(
					`shape-${response.getShapeId()}-time-${timestamp?.getSeconds()}-user-${location.getUserId()}`,
				);

				if (coordinate !== undefined) message.setCoordinate(coordinate);

				const senderId = location.getUserId();
				if (senderId !== undefined) message.setSenderId(senderId);
				message.setWorkspaceId(shape.workspaceId);
				// message.setSosSessionId(sosEvent.getSessionId());
				message.setOutgoing(false);
				if (timestamp !== undefined) message.setTimestamp(timestamp);
				// message.setSosEventType(sosEvent.getType());

				const messageObject: IMessage = message.toObject();
				messageObject.geofenceTraversalDirection = response.getDirection();
				messageObject.shapeId = shape.id;

				return [emitMessageReceivedFromStream(messageObject)];
			}

			case NotificationType.PINGPONGNOTIFICATION: {
				const pongResponse = PongResponse.deserializeBinary(
					notification.getData_asU8(),
				).toObject();
				if (pongResponse === undefined) return [noOp];

				const lastPingSent = selectLastPingSent(state);

				// if the pong doesn't contain the latest ping, ignore it
				if (lastPingSent?.seconds !== pongResponse.pingTimestamp?.seconds) return [noOp()];

				// note: uncomment this log each volley of the ping-pong health check
				// logVolley(pongResponse, state);

				return [
					appActions.receivePongEvent(pongResponse),
					appActions.sendPing.request({
						data: timestampFromMoment(moment()).toObject(),
					}),
				];
			}

			case NotificationType.NEWFILEUPLOADEDNOTIFICATION: {
				const response = FileMetadata.deserializeBinary(notification.getData_asU8());

				console.log("Received new file upload notification", response);
				return [emitFileReceivedFromStream(response.toObject())];
			}

			case NotificationType.INTEGRATIONCONNECTIONNOTIFICATION: {
				const response = IntegrationConnection.deserializeBinary(
					notification.getData_asU8(),
				);
				console.log("did receive integration connection over grpc", response.toObject());
				return [identityActions.updateIntegrationConnectedState(response.toObject())];
			}
		}
	} catch (e) {
		Sentry.captureException("Error processing notification", e);
	}

	return [noOp()];
}

/* Utility function to log each volley of the ping-pong health check  */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function logVolley(pongResponse: PongResponse.AsObject, state: RootState) {
	const pongTimestamp = pongResponse.pongTimestamp;
	const lastPongReceived = selectLastPongReceived(state);
	if (pongTimestamp !== undefined && lastPongReceived !== undefined) {
		const lastPongMoment = timestampToMoment(lastPongReceived);

		const pongMoment = timestampToMoment(pongTimestamp);

		const delta = pongMoment.diff(lastPongMoment, "seconds");

		if (delta > 120) console.debug("data gap");

		console.log(`last pong received: `, delta, lastPongMoment.format("HH:mm"));
	}
}

/**
 * Open message stream with automatic reconnect
 */
const apiNotificationStreamOpenEpic: Epic<Action, Action, RootState> = (action$, state$) =>
	action$.pipe(
		ofType(apiNotificationStreamOpen.type),
		switchMap(() => {
			return someGrpc.notificationStreamManager.open().pipe(
				backoffRetry(),
				mergeMap((it) => {
					if (isStreamLifecycleEvent(it)) {
						if (it.event === "connect") {
							return [
								// this initiates the game of ping pong between the client and the server
								appActions.sendPing.request({
									data: timestampFromMoment(moment()).toObject(),
								}),
							];
						} else {
							console.warn(`unhandled stream lifecycle event: ${it.event}`);
							return [noOp];
						}
					}

					return from(processNotification(it, state$.value));
				}),
			);
		}),
	);

const apiNotificationStreamCloseEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(apiNotificationStreamClose.type),
		switchMap(() => {
			someGrpc.notificationStreamManager.close();
			return of(apiNotificationStreamDisconnected());
		}),
	);

const setActiveWorkspaceIdEpic: Epic<Action> = (action$, state$) =>
	action$.pipe(
		filter(setActiveWorkspaceId.match),
		switchMap((action) => {
			const actions: Action[] = [];

			const workspaceId = action.payload;
			const contact = selectSelfAccounts(state$.value).find(
				(it) => it.workspaceId === workspaceId,
			);
			if (contact !== undefined) actions.push(setActiveUserAccountId(contact.id));

			return actions;
		}),
	);

const updateActiveWorkspaceIdEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(updateActiveWorkspaceId.type),
		switchMap((action) => {
			const actions: Action[] = [];
			// actions.push(setIsInitialLoadComplete(false));
			// actions.push(signedOut());
			actions.push(setActiveWorkspaceId((action as PayloadAction<string>).payload));
			actions.push(resetState());
			return actions;
		}),
	);

const updateActiveOrganizationIdEpic: Epic<Action> = (action$, state$) =>
	action$.pipe(
		ofType(updateActiveOrganizationId.type),
		switchMap((action) => {
			const actions: Action[] = [];
			const newActiveOrgId = (action as PayloadAction<string>).payload;
			const newActiveWorkspaceId =
				selectTeamWorkspaces(state$.value).find(
					(workspace) => workspace.organizationId === newActiveOrgId,
				)?.id ?? PERSONAL_WORKSPACE_ID;

			actions.push(setIsInitialLoadComplete(false));
			actions.push(signedOut());
			actions.push(resetState());
			actions.push(setActiveOrganizationId(newActiveOrgId));
			actions.push(setActiveWorkspaceId(newActiveWorkspaceId));

			return actions;
		}),
	);

const updateUserAccountEpic: Epic<Action> = (action$, state$) =>
	action$.pipe(
		ofType(updateUserAccount.type),
		switchMap((action) => {
			return [
				assetActions.fetchAssets.request(),
				organizationActions.getAssets.request(),
				organizationMemberActions.getMembers.request(),
			];
		}),
	);

const fetchDetailedAppDataEpic: Epic<Action> = (action$, state$) =>
	action$.pipe(
		ofType(refreshDetailedAppData.type),
		switchMap((it) => {
			if (AuthController.service.getCurrentAuthUser === undefined) {
				console.log("no signed in user; skipping requests");
				return [];
			}

			const actions: Action[] = [];
			actions.push(workspaceActions.fetchWorkspaces.request());
			actions.push(workspaceActions.fetchDevices.request());
			actions.push(assetActions.fetchAssets.request());
			actions.push(featureActions.fetchFeatures.request());
			actions.push(
				trackingRouteActions.getLastKnown.request({ data: undefined, noTimeout: true }),
			);
			actions.push(shapeActions.getShapes.request());
			actions.push(waypointActions.fetch.request());
			actions.push(deviceActions.fetch.request());
			actions.push(conversationActions.fetch.request());
			actions.push(getReadTimestamps());
			actions.push(fetchTrackingFilters());
			actions.push(fetchTrackingSettings());
			actions.push(fetchWorkspaceFilters());
			actions.push(mapLayersSlice.actions.init());
			actions.push(initMapView());

			const selectedConversation = selectSelectedConversation(state$.value);

			if (selectedConversation) {
				actions.push(
					messageActions.get.request({
						data: {
							conversationInfo: {
								conversationId: selectedConversation.id,
								workspaceId: selectedConversation.workspaceId,
							},
						},
						noTimeout: true,
					}),
				);
			}

			actions.push(deviceActions.fetchSummary.request());

			// this initiates the game of ping pong between the client and the server
			actions.push(
				appActions.sendPing.request({
					data: timestampFromMoment(moment()).toObject(),
				}),
			);

			if (selectHasTeamWorkspaces(state$.value)) {
				actions.push(apiHealthActivityRequest());
				actions.push(apiSosEventsRequest());
			}

			// if we have an organization, request organization data
			if (selectHasActiveOrganization(state$.value)) {
				// actions.push(appActions.fetchCapabilities.request({ data: undefined }));

				actions.push(organizationMemberActions.getMembers.request());
				actions.push(organizationMemberActions.getIntegrations.request());
				actions.push(organizationDeviceActions.getDevices.request());

				actions.push(organizationActions.getAssets.request());
				actions.push(organizationActions.getWorkspaces.request());

				actions.push(organizationActions.getLicense.request());
				actions.push(organizationActions.getAllDeviceUsage.request());
				actions.push(organizationActions.getIntegrationAccounts.request());
				actions.push(sensorsActions.getSensors.request());
				actions.push(apiKeysActions.getOrganizationApiKeys.request());
			}

			return actions;
		}),
	);

const fetchInitialAppDataEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(refreshInitialAppData.type),
		switchMap(() => {
			console.log("Requesting initial app data");
			return forkJoin({
				workspaces: grpc.prepareRequest(someGrpc.getWorkspaces, true),
				organizations: grpc.prepareRequest(someGrpc.fetchOrganizations, true),
				selfAccounts: grpc.prepareRequest(someGrpc.getSelfAccounts, true),
			}).pipe(
				catchError((e) => {
					console.log("Error fetching initial app data");
					if (e.code === 5) {
						console.info("The user probably doesn't have an account yet");
						return of({
							workspaces: new GetWorkspacesResponse(),
							organizations: new GetOrganizationsResponse(),
							selfAccounts: new GetSelfAccountsResponse(),
						});
					} else {
						// not sure of the error we received, still throw the error.
						Sentry.captureException(e);
						throw e;
					}
				}),
				map((result) => {
					console.log("Received initial app data");
					const user = AuthController.service.getCurrentAuthUser();
					if (user === undefined) return [];

					const selfAccounts = result.selfAccounts.toObject();

					const selfIdentity = selfAccounts.identity;
					const organizations = result.organizations.toObject().organizationsList;
					// set the active workspace
					const workspaces = result.workspaces
						.toObject()
						.workspacesList.map((workspace) => {
							if (workspace.id.isEmpty()) {
								workspace.id = PERSONAL_WORKSPACE_ID;
							}
							return workspace;
						});

					const actions: Action[] = [];

					const getAssetsResponse: GetAssetsResponse.AsObject = {
						usersList: selfAccounts.usersList,
						identitiesList: [selfAccounts.identity].mapNotNull((it) => it),
						accountsList: [],
					};

					actions.push(
						assetActions.fetchAssets.fulfilled({
							requestId: "",
							data: getAssetsResponse,
						}),
					);

					actions.push(setActiveAuthId(user.id));

					if (organizations.isNotEmpty()) {
						const storedOrganizationId =
							UserSource.getInstance().getStoredOrganizationIdForCurrentUser();

						const foundOrganization = organizations.find(
							(org) => org.id === storedOrganizationId,
						);

						const primaryOrganization =
							selfIdentity?.organizationId !== undefined
								? organizations.find(
										(org) => org.id === selfIdentity.organizationId,
									)
								: undefined;

						actions.push(
							organizationsSlice.actions.emitOrganizationsReceived(organizations),
						);

						if (storedOrganizationId === null) {
							actions.push(setActiveOrganizationId("-1"));
						} else if (foundOrganization !== undefined) {
							actions.push(setActiveOrganizationId(foundOrganization.id));
						} else if (primaryOrganization !== undefined) {
							actions.push(setActiveOrganizationId(primaryOrganization.id));
						} else {
							actions.push(setActiveOrganizationId(organizations[0].id));
						}
					} else {
						amplitude.logEvent("W| web app loading; no organization");
					}

					if (workspaces.isNotEmpty()) {
						actions.push(emitWorkspacesReceived(workspaces));

						const storedWorkspaceId = UserSource.getInstance().getStoredWorkspaceId(
							user.id,
						);

						const teamWorkspaces = workspaces.filter((it) => WorkspaceUtil.isTeam(it));

						const selectDefaultWorkspace = () => {
							// Select active to a non-personal workspace for user, if possible.
							const nonPersonal = teamWorkspaces.firstOrUndefined();
							if (nonPersonal !== undefined) {
								console.debug(
									"getWorkspaces: will assign non-personal workspace as default",
								);
								actions.push(setActiveWorkspaceId(nonPersonal.id));
								actions.push(setActiveUserAccountId(nonPersonal.userAccountId));
								actions.push(setActiveIdentityId(nonPersonal.identityId));
							} else if (workspaces.isNotEmpty()) {
								console.debug(
									"getWorkspaces: will assign personal workspace as default",
								);
								const first = workspaces.first();
								actions.push(setActiveWorkspaceId(first.id));
								actions.push(setActiveUserAccountId(first.userAccountId));
								actions.push(setActiveIdentityId(first.identityId));
							}
						};

						if (workspaces.isNotEmpty() && storedWorkspaceId === undefined) {
							selectDefaultWorkspace();
						} else if (storedWorkspaceId !== undefined) {
							const stored = workspaces.find(
								(workspace) => workspace.id === storedWorkspaceId,
							);
							if (stored !== undefined) {
								actions.push(setActiveWorkspaceId(stored.id));
								actions.push(setActiveUserAccountId(stored.userAccountId));
								actions.push(setActiveIdentityId(stored.identityId));
							} else if (storedWorkspaceId === PERSONAL_WORKSPACE_ID) {
								// this is the personal workspace
								actions.push(setActiveWorkspaceId(PERSONAL_WORKSPACE_ID));
							} else {
								console.error(
									`unknown workspace state; storedWorkspaceId=${storedWorkspaceId}`,
								);
								selectDefaultWorkspace();
							}
						}
					}

					const hasTeamWorkspace = WorkspaceUtil.hasTeamWorkspace(workspaces);
					const hasOrganization = organizations.isNotEmpty();

					const uid =
						!Config.firebase.enable && selfIdentity !== undefined
							? selfIdentity.id
							: user.id;

					const appUser: IUser = {
						uid: uid,
						name: selfIdentity?.fullName ?? user.displayName ?? "",
						email: user.email ?? undefined,
						username: selfIdentity?.username ?? "",
						phone: user.phoneNumber ?? undefined,
						isAnonymous: user.isAnonymous,
						hasWorkspace: hasTeamWorkspace,
						hasOrganization: hasOrganization,
					};

					if (!Config.firebase.enable) {
						(AuthController.service as ISomewearAuthService).setCurrentAuthUser({
							id: uid,
							displayName: selfIdentity?.fullName?.isNotEmpty()
								? selfIdentity.fullName
								: selfIdentity?.username,
							username: selfIdentity?.username,
							isAnonymous: user.isAnonymous,
						});
					}

					actions.push(setUser(appUser));

					// NOTE: important to only call this once the phone number has been
					// verified to avoid creating two users in the backend where the case
					// that there was already a phone number user
					if (
						appUser.phone ||
						appUser.email?.includes("someweardev") ||
						appUser.email?.toLowerCase().endsWith("@somewear.co")
					) {
						// initial load will complete after the subscription request succeeds
						actions.push(subscriptionsSlice.actions.apiSubscriptionsRequest());
					} else {
						// we still need to gather information for the user so show setup
						actions.push(setIsInitialLoadComplete(true));
					}

					// setAuthLoaded(true);

					return actions;
				}),
				mergeMap((action) => action),
				catchError((e) => {
					const actions: Action[] = [];
					console.error(`Failed to initialize the app;`, e);
					Sentry.captureException(e);
					if (e.code === StatusCode.UNAUTHENTICATED) {
						return [unauthorizedError()];
					}
					return actions;
					// setAuthLoaded(true);
				}),
			);
		}),
	);

export const signInEpic: ActionSetEpic<SignInRequest.AsObject, SignInResponse.AsObject> = (
	action$,
	state$,
) =>
	createActionSetEpicHandler(action$, state$, appActions.signIn, (payload) => {
		const request = new SignInRequest();
		request.setUsername(payload.data.username);
		request.setPassword(payload.data.password);
		return Api.signIn(request);
	});

export const signUpEpic: ActionSetEpic<SignInRequest.AsObject, SignInResponse.AsObject> = (
	action$,
	state$,
) => {
	return createActionSetEpicHandler(action$, state$, appActions.signUp, (payload) => {
		const request = new SignInRequest();
		request.setUsername(payload.data.username);
		request.setPassword(payload.data.password);
		return Api.signUp(request);
	});
};

export const updatePasswordEpic: ActionSetEpic<
	UpdatePasswordArgs,
	UpdateUserPasswordResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, appActions.updatePassword, (payload) => {
		return AuthController.service.reauthenticate$(payload.data.currentPassword).pipe(
			mergeMap(() => {
				if (payload.data.newPassword !== payload.data.newPasswordConfirm) {
					return throwError(() => ({
						name: "ValidationError",
						message: "Passwords do not match",
					}));
				}

				return grpc.prepareRequestWithPayload(someGrpc.updateUserPassword, payload.data);
			}),
		);
	});
};

const fetchAssetsEpic = actionSetEpicHandlerBuilder(assetActions.fetchAssets, () =>
	grpc.prepareRequest(someGrpc.fetchAssets),
);

const fetchFeaturesEpic = actionSetEpicHandlerBuilder(featureActions.fetchFeatures, () =>
	grpc.prepareRequest(someGrpc.fetchFeatures),
);

const fetchCapabilitiesEpic = actionSetEpicHandlerBuilder(featureActions.fetchCapabilities, () =>
	grpc.prepareRequest(someGrpc.fetchCapabilities),
);

const updateIdentityStyleEpic: ActionSetEpic<
	UpdateStyleSettingsRequest.AsObject,
	UserIdentityResponse.AsObject
> = (action$, state$) =>
	createActionSetEpicHandler(action$, state$, identityActions.updateIdentityStyle, (payload) =>
		grpc.prepareRequestWithPayload(someGrpc.updateIdentityStyle, payload.data),
	);

const updateWorkspaceStyleEpic: ActionSetEpic<
	UpdateStyleSettingsRequest.AsObject,
	WorkspaceResponse.AsObject
> = (action$, state$) =>
	createActionSetEpicHandler(
		action$,
		state$,
		appActions.updateWorkspaceStyle,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.updateWorkspaceStyle, payload.data),
		(payload) => {
			return {
				onPending: `Updating workspace style...`,
				onRejected: `Failed to update the workspace style.`,
				onFulfilled: `Successfully updated the workspace style.`,
			};
		},
	);

const joinOrganizationWorkspaceFulfilledEpic: Epic<Action> = (actions$) => {
	return actions$.pipe(
		filter(organizationActions.joinWorkspace.fulfilled.match),
		mergeMap((response) => {
			return [
				setActiveWorkspaceId(response.payload.data.usersList.first().workspaceId),
				refreshDetailedAppData(),
				resetDateFilter(),
				resetWorkspaceFilters(),
				setFilteredWorkspaceId(WORKSPACE_ID_ALL),
			];
		}),
	);
};

const archiveUserEpic: Epic<Action<unknown>> = (action$, state$) => {
	return action$.pipe(
		filter(emitUserAccountChangeFromServer.match),
		mergeMap((action) => {
			const actionsOut: Action[] = [];
			const activeIdentity = selectActiveIdentity(state$.value);

			if (!action.payload.isArchived) {
				return actionsOut;
			}

			actionsOut.push(emitAssetAccountsDeleted([action.payload]));

			const isActiveUserAccountArchived =
				activeIdentity !== undefined && activeIdentity.id === action.payload.identityId;

			const removalNotification = notificationsSlice.actions.emitInfoNotification({
				requestId: "notify-workspace-removal",
				data: undefined,
				message: "Workspace(s) you were a member of are no longer available.",
			});

			const workspace = selectWorkspaceById(state$.value, action.payload.workspaceId);

			if (isActiveUserAccountArchived) {
				actionsOut.push(removalNotification, emitActiveUserAccountDeleted(action.payload));

				if (workspace !== undefined) {
					// the user is not a member of the workspace's org, so the account and workspace should be deleted
					const deletedWorkspace: IWorkspace = {
						...workspace,
						// the user might have been archived or deleted, or the workspace might have been archived or deleted
						// we don't know which is true, so we'll just mark the workspace as deleted
						access: WorkspaceAccess.WORKSPACEACCESSDELETED,
					};

					actionsOut.push(emitWorkspaceChangeFromServer(deletedWorkspace));
				}

				const workspaceId = action.payload.workspaceId;
				const activeWorkspaceId = selectActiveWorkspaceId(state$.value);

				if (workspaceId === activeWorkspaceId) {
					actionsOut.push(setActiveWorkspaceId(PERSONAL_WORKSPACE_ID));
				}
			}

			return actionsOut;
		}),
	);
};

const removeWorkspaceRelatedDataEpic = transformerEpicBuilder(clearWorkspace, (action, state) => {
	// find the user accounts
	const assets = selectAllWorkspaceAssets(state);
	const workspaceAssets = assets.filter((it) => it.workspaceId === action.payload);

	return [
		emitAssetAccountsDeleted(workspaceAssets),
		notificationsSlice.actions.emitInfoNotification({
			requestId: "notify-workspace-removal",
			data: undefined,
			message: "Workspace(s) you were a member of are no longer available.",
		}),
	];
});

const createdOrganizationWorkspaceEpic: Epic<Action<unknown>> = (action$, state$) => {
	return action$.pipe(
		filter(organizationActions.createWorkspace.fulfilled.match),
		mergeMap((action) => {
			if (action.payload.data.workspace === undefined) return [];

			const workspace: IWorkspace = {
				...action.payload.data.workspace,
				userIsMember: true,
				isNew: true,
			};

			return [emitWorkspacesReceived([workspace]), refreshDetailedAppData()];
		}),
	);
};

export default combineEpics<any, any, RootState>(
	apiSubscriptionsEpic,
	apiNotificationStreamOpenEpic,
	apiNotificationStreamCloseEpic,
	setActiveWorkspaceIdEpic,
	updateActiveWorkspaceIdEpic,
	updateActiveOrganizationIdEpic,
	fetchInitialAppDataEpic,
	fetchDetailedAppDataEpic,
	fetchAssetsEpic,
	fetchFeaturesEpic,
	fetchCapabilitiesEpic,
	sendPingEpic,
	signInEpic,
	signUpEpic,
	signedOutEpic,
	updateIdentityStyleEpic,
	updatePasswordEpic,
	updateUserAccountEpic,
	updateWorkspaceStyleEpic,
	sendPingEpic,
	joinOrganizationWorkspaceFulfilledEpic,
	removeWorkspaceRelatedDataEpic,
	archiveUserEpic,
	createdOrganizationWorkspaceEpic,
);
