import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { DeviceEventResponse, DeviceSummaryResponse } from "@somewear/api";
import {
	DeviceSettings,
	DeviceSettingsEventType,
	DeviceSettingsResponse,
	DeviceStatusResponse,
	OptionalBool,
} from "@somewear/api";
import {
	type ButtonFunctionMap,
	RadioMode,
	RadioPowerMode,
	type RadioPowerModeMap,
} from "@somewear/api/src/proto/command_public_proto_pb";
import { emitAddUserAccountFromServer, emitUserAccountChangeFromServer } from "@somewear/asset";
import type { IDeviceTransfer } from "@somewear/device";
import { apiDeviceRecordUpdate, deviceActions, deviceTransferActions } from "@somewear/device";
import type { Dict } from "@somewear/model";
import { deviceHasNodeSerial, Sentry, toOptionalBool } from "@somewear/model";
import _, { cloneDeep, isEqual } from "lodash";

import {
	highSpeedRadioFrequencies,
	lowSpeedRadioFrequencies,
	type TrackingInterval,
} from "./settings.model";

type SettingsState = {
	deviceSettings: Dict<DeviceSettingsResponse.AsObject>;
	deviceStatus: Dict<DeviceStatusResponse.AsObject>;
	deviceSettingsPending: Dict<DeviceSettingsResponse.AsObject>;
	deviceUserIds: Dict<string | undefined>;
	deviceTransfers: Dict<IDeviceTransfer>;
	globalTrackingInterval?: TrackingInterval;
	//globalGpsInterval?: number;
	loading: boolean;
	showArchived: boolean;
	showBorrowed: boolean;
	selectedDeviceDict: Dictionary<boolean>;
};

export enum ChangeableFields {
	ActivationStatus = "activationStatus",
	Altitude = "altitude",
	Backhaul = "backhaul",
	Battery = "battery",
	ButtonFunction = "buttonFunction",
	Name = "name",
	TrackingInterval = "trackingInterval",
	RadioMode = "radioMode",
	RadioFrequency = "radioFrequency",
	RadioPowerMode = "radioPowerMode",
	User = "user",
	Workspace = "workspace",
}

const initialState: SettingsState = {
	deviceSettings: {},
	deviceStatus: {},
	deviceUserIds: {},
	deviceSettingsPending: {},
	deviceTransfers: {},
	loading: true,
	showArchived: false,
	showBorrowed: true,
	selectedDeviceDict: {},
};

const nodeOnlySettings: (keyof DeviceSettings.AsObject)[] = [
	"lowSpeedFrequencyHz",
	"highSpeedFrequencyHz",
	"buttonFunction",
	"radioMode",
	"radioPowerMode",
	"enableBackhaul",
];

export function getOrCreateSettings(
	deviceSettings: Dict<DeviceSettingsResponse.AsObject>,
	serial: string,
): DeviceSettingsResponse.AsObject {
	let settingsResponse: DeviceSettingsResponse.AsObject;
	if (serial in deviceSettings) {
		settingsResponse = _.cloneDeep(deviceSettings[serial]);
		settingsResponse.serial = serial;
		settingsResponse.settings!.trackingOn = true;
	} else {
		settingsResponse = new DeviceSettingsResponse().toObject();
		settingsResponse.serial = serial;
		settingsResponse.settings = new DeviceSettings().toObject();
		settingsResponse.settings.trackingOn = true;
	}

	if (settingsResponse.settings?.lowSpeedFrequencyHz === 0) {
		settingsResponse.settings.lowSpeedFrequencyHz = lowSpeedRadioFrequencies.LOWSPEEDAMERICAS1;
	}

	if (settingsResponse.settings?.highSpeedFrequencyHz === 0) {
		settingsResponse.settings.highSpeedFrequencyHz =
			highSpeedRadioFrequencies.HIGHSPEEDAMERICAS1;
	}

	if (settingsResponse.settings?.enableBackhaul === OptionalBool.OPTIONALBOOLNONE) {
		settingsResponse.settings.enableBackhaul = OptionalBool.OPTIONALBOOLFALSE;
	}

	if (
		settingsResponse.settings?.radioMode === RadioMode.RADIOMODENONE ||
		settingsResponse.settings?.radioMode === RadioMode.RADIOMODESTEALTH
	) {
		settingsResponse.settings.radioMode = RadioMode.RADIOMODEDISABLED;
	}

	if (settingsResponse.settings?.radioPowerMode === RadioPowerMode.RADIOPOWERMODENONE) {
		settingsResponse.settings.radioPowerMode = RadioPowerMode.RADIOPOWERMODELOW;
	}

	return settingsResponse;
}

function setTracking(state: SettingsState, serial: string, set: boolean) {
	if (serial in state.deviceSettingsPending) {
		state.deviceSettingsPending[serial].settings!.trackingOn = set;
	} else {
		state.deviceSettingsPending[serial] = getOrCreateSettings(state.deviceSettings, serial);
		state.deviceSettingsPending[serial].settings!.trackingOn = set;
		if (state.deviceSettingsPending[serial].settings!.trackingInterval === 0) {
			state.deviceSettingsPending[serial].settings!.trackingInterval = 1800;
		}
		if (state.deviceSettingsPending[serial].settings!.gpsInterval === 0) {
			state.deviceSettingsPending[serial].settings!.gpsInterval = 1800;
		}
	}
}

function removeOutdatedPendingChanges(state: SettingsState, serial: string) {
	if (
		isEqual(
			state.deviceSettingsPending[serial]?.settings,
			state.deviceSettings[serial]?.settings,
		)
	) {
		const newDeviceSettingsPending = cloneDeep(state.deviceSettingsPending);
		delete newDeviceSettingsPending[serial];

		state.deviceSettingsPending = newDeviceSettingsPending;
	}
}

function updateSettings<T extends keyof DeviceSettings.AsObject>({
	state,
	serial,
	key,
	value,
}: {
	state: SettingsState;
	serial: string;
	value: DeviceSettings.AsObject[T];
	key: T;
}) {
	if (nodeOnlySettings.includes(key) && !deviceHasNodeSerial(serial)) {
		console.warn(`Device ${serial} is not a node, skipping setting ${key}`);
		return;
	}

	if (serial in state.deviceSettingsPending) {
		state.deviceSettingsPending[serial].settings![key] = value;
		removeOutdatedPendingChanges(state, serial);
	} else {
		state.deviceSettingsPending[serial] = getOrCreateSettings(state.deviceSettings, serial);
		state.deviceSettingsPending[serial].settings![key] = value;
	}
}

function setDeviceSettings(
	state: SettingsState,
	serial: string,
	settings: DeviceSettingsResponse.AsObject,
) {
	const THREE_HOURS_IN_SECONDS = 10800;
	if (
		settings.type === DeviceSettingsEventType.SETTINGSCOMMANDAPPLIED &&
		settings.settingsCommand !== undefined &&
		(settings.settingsCommand.gpsinterval > THREE_HOURS_IN_SECONDS ||
			settings.settingsCommand.trackinginterval > THREE_HOURS_IN_SECONDS)
	) {
		// guard to avoid extreme tracking intervals from being applied
		// this was added to protect against a firmware bug that suggested the device had a tracking interval measured in months
		console.warn(`Unexpected settings update`, settings.settingsCommand);
		Sentry.captureException(`Unexpected settings update: greater than three hours`);
		return;
	}

	if (!(serial in state.deviceSettings)) {
		// initialize the settings
		state.deviceSettings[serial] = new DeviceSettingsResponse().toObject();
	}

	// When the last settings update happened on device. It has a different signature.
	if (
		settings.type === DeviceSettingsEventType.SETTINGSCOMMANDAPPLIED &&
		settings.settingsCommand !== undefined
	) {
		const command = settings.settingsCommand;

		state.deviceSettings[serial].settings = {
			gpsInterval: command.gpsinterval,
			batteryReporting: command.batteryReporting,
			enableAltitudeReporting: command.includeAltitude,
			trackingInterval: command.trackinginterval,
			trackingOn: false, // setting false so it can be flipped to true to initiate tracking
			// trackingOn: command.automaticTracking, // trackingOn is mapped to automaticTracking in the firmware
			sentTimestamp: command.sentTimestamp,
			enableBackhaul: command.backhaulEnabled,
			enableSpeedAndCourseReporting: command.includeSpeedAndCourse,
			lowSpeedFrequencyHz: command.lowSpeedFrequencyHz,
			highSpeedFrequencyHz: command.highSpeedFrequencyHz,
			radioMode: command.radioMode,
			radioPowerMode: command.radioPowerMode,
			buttonFunction: command.button1,
			meshVersionToDeploy: command.meshVersionToDeploy,
			iridiumNoSleep: toOptionalBool(command.nosleep),
		};
	} else if (settings.settings !== undefined) {
		state.deviceSettings[serial].settings = settings.settings;
	} else {
		console.warn(`Unexpected settings update; type=${settings.type}`);
		Sentry.captureException(`Unexpected settings update: unknown type`);
		return;
	}
	state.deviceSettings[serial].timestamp = settings.timestamp;
	state.deviceSettings[serial].type = settings.type;
}

function setDeviceStatus(
	state: SettingsState,
	serial: string,
	status: DeviceStatusResponse.AsObject,
) {
	if (!(serial in state.deviceStatus)) {
		state.deviceStatus[serial] = new DeviceStatusResponse().toObject();
	}
	state.deviceStatus[serial].battery = status.battery;
	state.deviceStatus[serial].firmwareversion = status.firmwareversion;
	state.deviceStatus[serial].timestamp = status.timestamp;
	if (status.lastHeartbeat?.seconds !== undefined && status.lastHeartbeat.seconds > 0)
		state.deviceStatus[serial].lastHeartbeat = status.lastHeartbeat;
}

export const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		apiDeviceStatusUpdate(state, action: PayloadAction<DeviceSummaryResponse.AsObject>) {
			const deviceSummary = action.payload;

			if (deviceSummary.settings !== undefined) {
				setDeviceSettings(state, deviceSummary.serial, deviceSummary.settings);
			}

			if (deviceSummary.status !== undefined) {
				setDeviceStatus(state, deviceSummary.serial, deviceSummary.status);
			}

			if (deviceSummary.user !== undefined) {
				state.deviceUserIds[deviceSummary.serial] = deviceSummary.user.id;
			} else {
				state.deviceUserIds[deviceSummary.serial] = deviceSummary.userId;
			}
		},
		apiDeviceEventSuccess(state, action: PayloadAction<DeviceEventResponse.AsObject[]>) {
			action.payload.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.settings.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.status.serial, event.status);
				}
			});
		},
		toggleTracking(state, action: PayloadAction<{ serial: string; set: boolean }>) {
			setTracking(state, action.payload.serial, action.payload.set);
		},
		setRadioFrequency(
			state,
			action: PayloadAction<{ serial: string; low: number; high: number }>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.low,
				key: "lowSpeedFrequencyHz",
			});
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.high,
				key: "highSpeedFrequencyHz",
			});
		},
		toggleMesh(state, action: PayloadAction<{ serial: string; set: boolean }>) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.set
					? RadioMode.RADIOMODEENABLED
					: RadioMode.RADIOMODEDISABLED,
				key: "radioMode",
			});
		},
		toggleBattery(state, action: PayloadAction<{ serial: string; set: boolean }>) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.set,
				key: "batteryReporting",
			});
		},
		toggleEnableAltitudeReporting(
			state,
			action: PayloadAction<{ serial: string; set: boolean }>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.set,
				key: "enableAltitudeReporting",
			});
		},
		toggleEnableBackhaulReporting(
			state,
			action: PayloadAction<{ serial: string; set: boolean }>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: toOptionalBool(action.payload.set),
				key: "enableBackhaul",
			});
		},
		setRadioPowerMode(
			state,
			action: PayloadAction<{
				serial: string;
				value: RadioPowerModeMap[keyof RadioPowerModeMap];
			}>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value,
				key: "radioPowerMode",
			});
		},
		setTrackingInterval(
			state,
			action: PayloadAction<{ serial: string; value: TrackingInterval }>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.shareRate,
				key: "trackingInterval",
			});
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.logRate,
				key: "gpsInterval",
			});
		},
		setGpsInterval(state, action: PayloadAction<{ serial: string; value: TrackingInterval }>) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.logRate,
				key: "trackingInterval",
			});
		},
		setButtonFunction(
			state,
			action: PayloadAction<{
				serial: string;
				value: ButtonFunctionMap[keyof ButtonFunctionMap];
			}>,
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value,
				key: "buttonFunction",
			});
		},
		bulkUpdateMeshToggleForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload
						? RadioMode.RADIOMODEENABLED
						: RadioMode.RADIOMODEDISABLED,
					key: "radioMode",
				});
			});
		},
		bulkUpdateRadioFrequencyForSelected(
			state,
			action: PayloadAction<{ low: number; high: number }>,
		) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload.low,
					key: "lowSpeedFrequencyHz",
				});
				updateSettings({
					state,
					serial,
					value: action.payload.high,
					key: "highSpeedFrequencyHz",
				});
			});
		},
		bulkUpdateButtonFunctionForSelected(
			state,
			action: PayloadAction<ButtonFunctionMap[keyof ButtonFunctionMap]>,
		) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "buttonFunction",
				});
			});
		},
		bulkUpdateRadioPowerModeForSelected(
			state,
			action: PayloadAction<RadioPowerModeMap[keyof RadioPowerModeMap]>,
		) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "radioPowerMode",
				});
			});
		},
		bulkUpdateAutoTrackingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				setTracking(state, serial, action.payload);
			});
		},
		bulkUpdateBatteryReportingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "batteryReporting",
				});
			});
		},
		bulkUpdateAltitudeReportingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "enableAltitudeReporting",
				});
			});
		},
		bulkUpdateBackhaulReportingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: toOptionalBool(action.payload),
					key: "enableBackhaul",
				});
			});
		},
		bulkUpdateTrackingIntervalsForSelected(state, action: PayloadAction<TrackingInterval>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload.shareRate,
					key: "trackingInterval",
				});
				updateSettings({
					state,
					serial,
					value: action.payload.logRate,
					key: "gpsInterval",
				});
			});
		},
		setAllGpsIntervals(state, action: PayloadAction<TrackingInterval>) {
			// state.globalGpsInterval = action.payload;
			Object.keys(state.deviceUserIds).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload.logRate,
					key: "gpsInterval",
				});
			});
		},
		setGlobalTrackingInterval(state, action: PayloadAction<TrackingInterval>) {
			state.globalTrackingInterval = action.payload;
		},
		/**
		setGlobalGpsInterval(state, action: PayloadAction<number>) {
			state.globalGpsInterval = action.payload;
		},
		 **/
		revertChanges(state) {
			state.deviceSettingsPending = {};
		},
		setShowArchived(state, action: PayloadAction<boolean>) {
			state.showArchived = action.payload;
		},
		setShowBorrowed(state, action: PayloadAction<boolean>) {
			state.showBorrowed = action.payload;
		},
		setDeviceSelected(state, action: PayloadAction<{ serial: string; selected: boolean }>) {
			state.selectedDeviceDict[action.payload.serial] = action.payload.selected;
		},
		setSelectedDeviceDict(state, action: PayloadAction<Dictionary<boolean>>) {
			state.selectedDeviceDict = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const user = action.payload;
			Object.keys(state.deviceUserIds).forEach((serial) => {
				if (state.deviceUserIds[serial] === user.id) {
					state.deviceUserIds[serial] = action.payload.id;
				}
			});
			// adapter.upsertOne(state, user);
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			console.log("added user");
			// maybe the user should be added to device users
		});
		builder.addCase(deviceActions.fetchSummary.fulfilled, (state, action) => {
			state.loading = false;
			const devices = action.payload.data.devicesList;
			devices.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.serial, event.status);
				}
				state.deviceUserIds[event.serial] = event.userId;
			});
		});
		builder.addCase(deviceActions.submitSettings.fulfilled, (state, action) => {
			state.deviceSettingsPending = {};
			const events = action.payload.data.eventsList;
			events.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.settings.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.status.serial, event.status);
				}
			});
		});
		builder.addCase(deviceTransferActions.applyQueuedTransfers.fulfilled, (state, action) => {
			const devices = action.payload.data.mapNotNull((it) => it.device);
			devices.forEach((device) => {
				state.deviceUserIds[device.serial] = device.userAccountId;
			});
		});
		builder.addCase(deviceTransferActions.unaryAssign.fulfilled, (state, action) => {
			const device = action.payload.data.device;
			if (device === undefined) return;
			state.deviceUserIds[device.serial] = device.userAccountId;
		});
		builder.addCase(deviceTransferActions.bulkAssign.fulfilled, (state, action) => {
			const devices = action.payload.data.mapNotNull((it) => it.device);
			devices.forEach((device) => {
				state.deviceUserIds[device.serial] = device.userAccountId;
			});
		});
		builder.addCase(apiDeviceRecordUpdate, (state, action) => {
			if (action.payload.asset !== undefined) {
				state.deviceUserIds[action.payload.record.serial] = action.payload.asset.id;
			}
		});
	},
});

export const {
	apiDeviceEventSuccess,
	toggleTracking,
	toggleBattery,
	toggleEnableAltitudeReporting,
	toggleEnableBackhaulReporting,
	setRadioFrequency,
	setTrackingInterval,
	setRadioPowerMode,
	toggleMesh,
	bulkUpdateMeshToggleForSelected,
	bulkUpdateButtonFunctionForSelected,
	bulkUpdateRadioPowerModeForSelected,
	bulkUpdateRadioFrequencyForSelected,
	bulkUpdateAutoTrackingForSelected,
	bulkUpdateBatteryReportingForSelected,
	bulkUpdateAltitudeReportingForSelected,
	bulkUpdateTrackingIntervalsForSelected,
	bulkUpdateBackhaulReportingForSelected,
	setGlobalTrackingInterval,
	setButtonFunction,
	revertChanges,
	setShowArchived,
	setShowBorrowed,
	setSelectedDeviceDict,
	setDeviceSelected,
} = settingsSlice.actions;
