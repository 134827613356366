import type {
	ArchiveMembersResponse,
	AssignDeviceResponse,
	ConfirmOrganizationClaimCodeResponse,
	CreateConversationResponse,
	CreateHealthActivityResponse,
	CreateOrganizationIntegrationResponse,
	CreateOrganizationResourceResponse,
	CreateOrganizationWorkspaceResponse,
	CreateResourceAccountResponse,
	CreateShapeResponse,
	CreateWorkspaceKeyApiResponse,
	DeleteConversationResponse,
	DeleteShapeResponse,
	DeviceEventResponseList,
	DeviceList,
	DeviceSettingsResponse,
	DeviceSummaryResponseList,
	ExportGpxResponse,
	GetActiveSessionEventsResponse,
	GetAssetsResponse,
	GetConversationsResponse,
	GetDevicesResponse,
	GetDeviceUsageResponse,
	GetFeaturesResponse,
	GetFileMetadataResponse,
	GetLocationsRequest,
	GetLocationsResponse,
	GetOrganizationBillingInfoResponse,
	GetOrganizationCapabilitiesResponse,
	GetOrganizationDataUsageForDevicesResponse,
	GetOrganizationDevicesResponse,
	GetOrganizationIdentitiesResponse,
	GetOrganizationIntegrationAccountsResponse,
	GetOrganizationIntegrationsResponse,
	GetOrganizationLicenseSummaryResponse,
	GetOrganizationMembersResponse,
	GetOrganizationsResponse,
	GetOrganizationWorkspacesResponse,
	GetRouteLocationsResponse,
	GetRoutesRequest,
	GetRoutesResponse,
	GetSensorsResponse,
	GetShapesResponse,
	GetSingleFileUrlResponse,
	GetUploadUrlResponse,
	GetWaypointsResponse,
	GetWorkspacesResponse,
	HealthActivityResponseList,
	InviteMembersResponse,
	InviteOrganizationMembersResponse,
	IssueWorkspaceInviteTokenResponse,
	JoinWorkspaceByInviteResponse,
	JoinWorkspaceRequest,
	LocationResponseList,
	MessageResponseList,
	NotificationPayload,
	OrganizationRecord,
	RemoveOrganizationIntegrationResponse,
	RemoveOrganizationMembersResponse,
	SendCotToTakServerResponse,
	SendMessageResponse,
	Shape,
	SignInResponse,
	SosEventResponse,
	UnassignDeviceResponse,
	UpdateOrganizationMemberPasswordResponse,
	UpdateOrganizationRoleResponse,
	UpdateShapeResponse,
	UpdateUserPasswordResponse,
	UpdateWorkspaceAccessResponse,
	UpdateWorkspaceAccountsResponse,
	UpdateWorkspaceRoleResponse,
	UserIdentityResponse,
	WorkspaceResponse,
	WorkspaceResponseList,
} from "@somewear/api";
import {
	ActivateDeviceRequest,
	ArchiveMembersRequest,
	ArchiveWorkspaceRequest,
	AssignDeviceRequest,
	BatchActivateDevicesRequest,
	BatchSuspendDevicesRequest,
	ClaimOrganizationRequest,
	ConfirmOrganizationClaimCodeRequest,
	CoordinateDto,
	CreateBeamAuthRequest,
	CreateConversationRequest,
	CreateHealthActivity,
	CreateHealthActivityRequest,
	CreateOrganizationIntegrationRequest,
	CreateOrganizationResourceRequest,
	CreateOrganizationTakServerRequest,
	CreateOrganizationWorkspaceRequest,
	CreateResourceAccountRequest,
	CreateShapeRequest,
	CreateTrackingLocationsRequest,
	CreateWaypoint,
	CreateWaypointRequest,
	CreateWorkspaceKeyApiRequest,
	DeleteConversationRequest,
	DeleteRouteRequest,
	DeleteShapeRequest,
	DeleteWorkspaceRequest,
	DeviceClient,
	DeviceSettings,
	DeviceSettingsRequest,
	DeviceSettingsRequestList,
	ExportGpxRequest,
	FeatureServiceClient,
	FileServiceClient,
	GetActiveSessionEventsRequest,
	GetAssetsRequest,
	GetConversationsRequest,
	GetDevicesSummaryRequest,
	GetFeaturesRequest,
	GetFileMetadataRequest,
	GetIdentityDevicesRequest,
	GetLastKnownRoutesRequest,
	GetLiveRoutesRequest,
	GetMessagesRequest,
	GetNotificationStreamRequest,
	GetOrganizationBillingInfoRequest,
	GetOrganizationCapabilitiesRequest,
	GetOrganizationDataUsageForDevicesRequest,
	GetOrganizationDevicesRequest,
	GetOrganizationDeviceUsageRequest,
	GetOrganizationIdentitiesRequest,
	GetOrganizationIntegrationAccountsRequest,
	GetOrganizationIntegrationsRequest,
	GetOrganizationLicenseSummaryRequest,
	GetOrganizationMembersRequest,
	GetOrganizationsRequest,
	GetOrganizationWorkspacesRequest,
	GetRecentHealthActivityRequest,
	GetRouteLocationsRequest,
	GetSelfAccountsRequest,
	GetSensorsRequest,
	GetShapesRequest,
	GetSingleFileUrlRequest,
	GetUploadUrlRequest,
	GetWaypointsRequest,
	GetWorkspaceDevicesRequest,
	GetWorkspaceMessagesRequest,
	GetWorkspacesRequest,
	HealthActivityClient,
	IdentityRecord,
	InviteMembersRequest,
	InviteOrganizationMembersRequest,
	IssueWorkspaceInviteTokenRequest,
	JoinOrganizationWorkspaceRequest,
	JoinWorkspaceByInviteRequest,
	LeaveWorkspaceRequest,
	LocationResponse,
	MessageRequest,
	MessagingClient,
	NotificationClient,
	OrganizationClient,
	RemoveOrganizationIntegrationRequest,
	RemoveOrganizationMembersRequest,
	RouteResponse,
	SendCotToTakServerRequest,
	SendMessageRequest,
	SendPingRequest,
	SendSosMessageRequest,
	SensorServiceClient,
	ShapeServiceClient,
	SosClient,
	SosMessageDto,
	StyleSettings,
	SuspendDeviceRequest,
	SystemClient,
	Timestamp,
	ToggleWorkspaceIntegrationRequest,
	TrackingClient,
	TrackingLocationDto,
	UnassignDeviceRequest,
	UpdateOrganizationIntegrationRoutingRequest,
	UpdateOrganizationMemberPasswordRequest,
	UpdateOrganizationRoleRequest,
	UpdateShapeRequest,
	UpdateStyleSettingsRequest,
	UpdateUserPasswordRequest,
	UpdateWorkspaceAccount,
	UpdateWorkspaceAccountList,
	UpdateWorkspaceRoleRequest,
	UserClient,
	UserResponse,
	WorkspaceClient,
} from "@somewear/api";
import type {
	CreateOrganizationApiClientResponse,
	DeleteOrganizationApiClientResponse,
	GetOrganizationApiClientsResponse,
} from "@somewear/api/src/proto/api/organization_pb";
import {
	CreateOrganizationApiClientRequest,
	DeleteOrganizationApiClientRequest,
	GetOrganizationApiClientsRequest,
} from "@somewear/api/src/proto/api/organization_pb";
import { RefreshAccessTokenRequest, RenameAssetRequest } from "@somewear/api/src/proto/api/user_pb";
import type { UpdatePasswordArgs } from "@somewear/auth";
import type {
	CreateHealthActivityForm,
	CreateIntegrationRequest,
	DownloadFileRequestPayload,
	GetWorkspaceFilesRequestPayload,
	IConfig,
	IntegrationConfig,
	IOrganizationAsset,
	IQueuedDeviceActivationChange,
	ISaveWaypointPayload,
	ITrackingRouteWithContact,
	MessageRequestWithState,
	MessageRequestWithStateAndTmpId,
	MessagesRequestPayload,
	SingleWorkspaceIntegrationConfig,
	UploadFileRequest,
} from "@somewear/model";
import {
	DeviceActivationChange,
	isFederatedTakServerIntegrationRequest,
	isWebhookIntegrationRequest,
	mapToEnableIntegration,
	mapToEnableIntegrationAccounts,
	mapToUpdateTakResourceAssignedWorkspaces,
} from "@somewear/model";
import sha256 from "crypto-js/sha256";
import type { ClientReadableStream, Metadata } from "grpc-web";
import moment from "moment";
import { firstValueFrom, forkJoin, from, of } from "rxjs";
import { map, mergeMap, toArray } from "rxjs/operators";

import type { IGrpcRequestHandler, IGrpcRequestHandlerWithPayload } from "./GrpcClient";
import { StreamData, StreamManager } from "./GrpcClient";
// eslint-disable-next-line import/namespace
import * as Mappers from "./somewearGrpcMappers";

// eslint-disable-next-line
const mappers = Mappers;

export const degreeMinuteDtoFromDecimalCoordinate = (coordinate: number): number => {
	const degrees = Math.trunc(coordinate);
	const minutes = (coordinate - degrees) * 60;
	const degMin = degrees * 100.0 + minutes;
	return Math.round(degMin * 10000.0);
};

export const getGrpcDomain = (config: IConfig) => {
	return config.somewear.gRPCDomain !== "auto"
		? config.somewear.gRPCDomain
		: window.location.origin;
};

class Clients {
	private static _instance: Clients;

	user: UserClient;
	file: FileServiceClient;
	messaging: MessagingClient;
	health: HealthActivityClient;
	sos: SosClient;
	workspace: WorkspaceClient;
	organization: OrganizationClient;
	tracking: TrackingClient;
	notification: NotificationClient;
	device: DeviceClient;
	featureService: FeatureServiceClient;
	sensorService: SensorServiceClient;
	shape: ShapeServiceClient;
	system: SystemClient;

	static get user() {
		return this._instance.user;
	}

	static get file() {
		return this._instance.file;
	}

	static get messaging() {
		return this._instance.messaging;
	}

	static get health() {
		return this._instance.health;
	}

	static get sos() {
		return this._instance.sos;
	}

	static get workspace() {
		return this._instance.workspace;
	}

	static get organization() {
		return this._instance.organization;
	}

	static get tracking() {
		return this._instance.tracking;
	}

	static get notification() {
		return this._instance.notification;
	}

	static get device() {
		return this._instance.device;
	}

	static get featureService() {
		return this._instance.featureService;
	}

	static get sensorService() {
		return this._instance.sensorService;
	}

	static get shape() {
		return this._instance.shape;
	}

	static get system() {
		return this._instance.system;
	}

	static init(config: IConfig) {
		this._instance = new Clients(config);
	}

	private constructor(config: IConfig) {
		const domain = getGrpcDomain(config);

		this.user = new UserClient(domain);
		this.file = new FileServiceClient(domain);
		this.messaging = new MessagingClient(domain);
		this.health = new HealthActivityClient(domain);
		this.sos = new SosClient(domain);
		this.workspace = new WorkspaceClient(domain);
		this.organization = new OrganizationClient(domain);
		this.tracking = new TrackingClient(domain);
		this.notification = new NotificationClient(domain);
		this.device = new DeviceClient(domain);
		this.featureService = new FeatureServiceClient(domain);
		this.sensorService = new SensorServiceClient(domain);
		this.shape = new ShapeServiceClient(domain);
		this.system = new SystemClient(domain);
	}
}

namespace SomewearGrpc {
	export const init = (config: IConfig) => {
		Clients.init(config);
	};

	export const refreshAccessToken: IGrpcRequestHandlerWithPayload<string, SignInResponse> = (
		metadata,
		payload,
	) => {
		const request = new RefreshAccessTokenRequest();
		request.setRefreshToken(payload);
		return Clients.user.refreshAccessToken(request, metadata);
	};

	export const sendPing: IGrpcRequestHandlerWithPayload<Timestamp.AsObject, SendPingRequest> = (
		metadata,
		payload,
	) => {
		const timestamp = new Timestamp();
		timestamp.setSeconds(payload.seconds);
		const request = new SendPingRequest();
		request.setPingTimestamp(timestamp);
		return Clients.system.sendPing(request, metadata);
	};

	export const getHealthActivity: IGrpcRequestHandler<HealthActivityResponseList> = (
		metadata,
	) => {
		return Clients.health.getRecentHealthActivity(
			new GetRecentHealthActivityRequest(),
			metadata,
		);
	};

	export const createHealthActivity: IGrpcRequestHandlerWithPayload<
		CreateHealthActivityForm,
		CreateHealthActivityResponse
	> = (metadata, payload) => {
		const request = new CreateHealthActivityRequest();
		const activity = new CreateHealthActivity();

		activity.setBreathingRate(payload.breathingRate);
		activity.setBreathingRateConfidence(payload.breathingRateConfidence);
		activity.setCoreTemperature(payload.coreTemperature);
		activity.setHeartRate(payload.heartRate);
		activity.setHeartRateConfidence(payload.heartRateConfidence);
		activity.setPosture(payload.posture);
		activity.setPostureValid(payload.posture > 0);
		activity.setTimestamp(moment().unix());
		request.setActivity(activity);

		return Clients.health.createNewHealthActivity(request, metadata);
	};

	export const updateUserPassword: IGrpcRequestHandlerWithPayload<
		UpdatePasswordArgs,
		UpdateUserPasswordResponse
	> = (metadata, payload) => {
		const request = new UpdateUserPasswordRequest();
		request.setNewPassword(payload.newPassword);
		return Clients.user.updateUserPassword(request, metadata);
	};

	export const getWorkspaces: IGrpcRequestHandler<GetWorkspacesResponse> = (metadata) => {
		return Clients.user.getWorkspaces(new GetWorkspacesRequest(), metadata);
	};

	export const getSelfAccounts = (metadata: Metadata) => {
		return Clients.user.getSelfAccounts(new GetSelfAccountsRequest(), metadata);
	};

	/*export const getWorkspaceAccounts: IGrpcRequestHandler<UserResponseList> = (metadata) => {
		const request = new GetWorkspaceAccountsRequest();
		request.setIncludeSelf(true);

		return Clients.user.getWorkspaceAccounts(request, metadata);
	};*/

	export const getWorkspaceDevices: IGrpcRequestHandler<DeviceList> = (metadata) => {
		const request = new GetWorkspaceDevicesRequest();
		return Clients.user.getWorkspaceDevices(request, metadata);
	};

	export const fetchAllWorkspaces: IGrpcRequestHandler<WorkspaceResponseList> = (metadata) => {
		const request = new GetWorkspacesRequest();
		return Clients.workspace.getWorkspaces(request, metadata);
	};

	export const getActiveSosEvents: IGrpcRequestHandler<GetActiveSessionEventsResponse> = (
		metadata,
	) => {
		return Clients.sos.getActiveSessionEvents(new GetActiveSessionEventsRequest(), metadata);
	};

	export const generateAdditionalWorkspaceKey: IGrpcRequestHandler<
		CreateWorkspaceKeyApiResponse
	> = (metadata) => {
		return Clients.workspace.createWorkspaceKey(new CreateWorkspaceKeyApiRequest(), metadata);
	};

	export const generateWorkspaceToken: IGrpcRequestHandlerWithPayload<
		string,
		IssueWorkspaceInviteTokenResponse
	> = (metadata, payload) => {
		const request = new IssueWorkspaceInviteTokenRequest();
		request.setWorkspaceId(payload);
		return Clients.workspace.issueWorkspaceInviteToken(request, metadata);
	};

	export const fetchOrganizations: IGrpcRequestHandler<GetOrganizationsResponse> = (metadata) => {
		return Clients.user.getOrganizations(new GetOrganizationsRequest(), metadata);
	};

	export const fetchOrganizationLicenseSummary: IGrpcRequestHandler<
		GetOrganizationLicenseSummaryResponse
	> = (metadata) => {
		return Clients.organization.getLicenseSummary(
			new GetOrganizationLicenseSummaryRequest(),
			metadata,
		);
	};

	export const fetchOrganizationMembers: IGrpcRequestHandler<GetOrganizationMembersResponse> = (
		metadata,
	) => {
		return Clients.organization.getMembers(new GetOrganizationMembersRequest(), metadata);
	};

	export const fetchOrganizationIntegrations: IGrpcRequestHandler<
		GetOrganizationIntegrationsResponse
	> = (metadata) => {
		return Clients.organization.getIntegrations(
			new GetOrganizationIntegrationsRequest(),
			metadata,
		);
	};

	export const createOrganizationWorkspace: IGrpcRequestHandlerWithPayload<
		CreateOrganizationWorkspaceRequest.AsObject,
		CreateOrganizationWorkspaceResponse
	> = (metadata, payload) => {
		const request = new CreateOrganizationWorkspaceRequest();
		request.setName(payload.name);
		request.setAddSelfAsMember(payload.addSelfAsMember);

		if (payload.styleSettings !== undefined) {
			const styleSettings = new StyleSettings();
			styleSettings.setStandardColor(payload.styleSettings.standardColor);
			request.setStyleSettings(styleSettings);
		}

		return Clients.organization.createWorkspace(request, metadata);
	};

	export const createOrganizationIntegration: IGrpcRequestHandlerWithPayload<
		CreateIntegrationRequest,
		CreateOrganizationIntegrationRequest
	> = (metadata, payload) => {
		const request = new CreateOrganizationIntegrationRequest();
		if (isFederatedTakServerIntegrationRequest(payload)) {
			// throw Error("Unable to create TAK integration via this endpoint at this time");
			request.assignFederatedTakIntegration(payload);
		} else if (isWebhookIntegrationRequest(payload)) {
			request.assignWebhookIntegration(payload);
		}

		return Clients.organization.createIntegration(request, metadata);
	};

	export const createOrganizationTakIntegration: IGrpcRequestHandlerWithPayload<
		CreateOrganizationTakServerRequest.AsObject,
		CreateOrganizationIntegrationResponse
	> = (metadata, payload) => {
		const request = new CreateOrganizationTakServerRequest();
		request.setName(payload.name);
		request.setHost(payload.host);
		request.setPort(payload.port);
		request.setClientCert(payload.clientCert);
		request.setClientCertPassword(payload.clientCertPassword);
		request.setServerCert(payload.serverCert);
		request.setServerCertPassword(payload.serverCertPassword);
		if (payload.beamAuth !== undefined) {
			const beamAuth = new CreateBeamAuthRequest();
			beamAuth.setUsername(payload.beamAuth.username);
			beamAuth.setPassword(payload.beamAuth.password);
			request.setBeamAuth(beamAuth);
		}
		return Clients.organization.createTakIntegration(request, metadata);
	};

	export const removeOrganizationIntegration: IGrpcRequestHandlerWithPayload<
		string,
		RemoveOrganizationIntegrationResponse
	> = (metadata, payload) => {
		const request = new RemoveOrganizationIntegrationRequest();
		request.setIdentityId(payload);
		return Clients.organization.removeIntegration(request, metadata);
	};

	export const createOrganizationResource: IGrpcRequestHandlerWithPayload<
		string,
		CreateOrganizationResourceResponse
	> = (metadata, payload) => {
		const request = new CreateOrganizationResourceRequest();
		request.setName(payload);
		return Clients.organization.createResource(request, metadata);
	};

	export const fetchOrganizationWorkspaces: IGrpcRequestHandler<
		GetOrganizationWorkspacesResponse
	> = (metadata) => {
		return Clients.organization.getWorkspaces(new GetOrganizationWorkspacesRequest(), metadata);
	};

	export const fetchOrganizationDevices: IGrpcRequestHandler<GetOrganizationDevicesResponse> = (
		metadata,
	) => {
		const request = new GetOrganizationDevicesRequest();
		request.setIncludeDevicePlans(true);
		return Clients.organization.getDevices(request, metadata);
	};

	export const fetchOrganizationIdentities: IGrpcRequestHandler<
		GetOrganizationIdentitiesResponse
	> = (metadata) => {
		return Clients.organization.getIdentities(new GetOrganizationIdentitiesRequest(), metadata);
	};

	export const fetchOrganizationDeviceUsage: IGrpcRequestHandlerWithPayload<
		string,
		GetDeviceUsageResponse
	> = (metadata, serial) => {
		const request = new GetOrganizationDeviceUsageRequest();
		request.setSerial(serial);
		return Clients.organization.getDeviceUsage(request, metadata);
	};

	export const getOrganizationApiKeys: IGrpcRequestHandler<GetOrganizationApiClientsResponse> = (
		metadata,
	) => {
		const request = new GetOrganizationApiClientsRequest();

		return Clients.organization.getOrganizationApiClients(request, metadata);
	};

	export const createOrganizationApiKey: IGrpcRequestHandlerWithPayload<
		CreateOrganizationApiClientRequest.AsObject,
		CreateOrganizationApiClientResponse
	> = (metadata, payload) => {
		const request = new CreateOrganizationApiClientRequest();
		request.setName(payload.name);

		return Clients.organization.createOrganizationApiClient(request, metadata);
	};

	export const deleteOrganizationApiKey: IGrpcRequestHandlerWithPayload<
		DeleteOrganizationApiClientRequest.AsObject,
		DeleteOrganizationApiClientResponse
	> = (metadata, payload) => {
		const request = new DeleteOrganizationApiClientRequest();
		request.setApiClientId(payload.apiClientId);

		return Clients.organization.deleteOrganizationApiClient(request, metadata);
	};

	export const fetchAllDeviceUsageByOrganization: IGrpcRequestHandler<
		GetOrganizationDataUsageForDevicesResponse
	> = (metadata) => {
		return Clients.organization.getDataUsageForDevices(
			new GetOrganizationDataUsageForDevicesRequest(),
			metadata,
		);
	};

	export const fetchOrganizationBillingInfo: IGrpcRequestHandler<
		GetOrganizationBillingInfoResponse
	> = (metadata) => {
		return Clients.organization.getOrganizationBillingInfo(
			new GetOrganizationBillingInfoRequest(),
			metadata,
		);
	};

	export const updateOrganizationAssetPassword: IGrpcRequestHandlerWithPayload<
		UpdateOrganizationMemberPasswordRequest.AsObject,
		UpdateOrganizationMemberPasswordResponse
	> = (metadata, payload) => {
		const request = new UpdateOrganizationMemberPasswordRequest();
		request.setIdentityId(payload.identityId);
		request.setNewPassword(payload.newPassword);

		return Clients.organization.updateMemberPassword(request, metadata);
	};

	export const unassignDevice: IGrpcRequestHandlerWithPayload<string, UnassignDeviceResponse> = (
		metadata,
		serial,
	) => {
		const request = new UnassignDeviceRequest();
		request.setSerial(serial!);
		return Clients.workspace.unassignDevice(request, metadata);
	};

	export const getLocations: IGrpcRequestHandlerWithPayload<
		GetLocationsRequest,
		GetLocationsResponse
	> = (metadata, request) => {
		return Clients.tracking.getLocations(request, metadata);
	};

	/**
	 * @deprecated Use getMessages going forward
	 */
	export const getWorkspaceMessages: IGrpcRequestHandlerWithPayload<
		MessagesRequestPayload,
		MessageResponseList
	> = (metadata, payload) => {
		const request = new GetWorkspaceMessagesRequest();
		if (payload?.before) {
			const timestamp = new Timestamp();
			timestamp.setSeconds(payload.before.seconds);
			timestamp.setNanos(payload.before.nanos);
			request.setBefore(timestamp);
		}

		return Clients.messaging.getWorkspaceMessages(request, metadata);
	};

	export const getMessages: IGrpcRequestHandlerWithPayload<
		MessagesRequestPayload,
		MessageResponseList
	> = (metadata, payload) => {
		const request = new GetMessagesRequest();
		if (payload.conversationInfo.workspaceId?.isNotEmpty()) {
			request.setWorkspaceId(payload.conversationInfo.workspaceId);
		}
		if (payload.conversationInfo.conversationId?.isNotEmpty()) {
			request.setConversationId(payload.conversationInfo.conversationId);
		}
		if (payload?.before) {
			const timestamp = new Timestamp();
			timestamp.setSeconds(payload.before.seconds);
			timestamp.setNanos(payload.before.nanos);
			request.setBefore(timestamp);
		}

		return Clients.messaging.getMessages(request, metadata);
	};

	export const fetchConversations: IGrpcRequestHandler<GetConversationsResponse> = (metadata) => {
		return Clients.messaging.getConversations(new GetConversationsRequest(), metadata);
	};

	export const assignOrganizationRole: IGrpcRequestHandlerWithPayload<
		UpdateOrganizationRoleRequest.AsObject,
		UpdateOrganizationRoleResponse
	> = (metadata, update) => {
		const request = new UpdateOrganizationRoleRequest();
		request.setIdentityId(update.identityId);
		request.setRole(update.role);
		return Clients.organization.updateOrganizationRole(request, metadata);
	};

	export const assignWorkspaceRole: IGrpcRequestHandlerWithPayload<
		UpdateWorkspaceRoleRequest.AsObject,
		UpdateWorkspaceRoleResponse
	> = (metadata, update) => {
		const request = new UpdateWorkspaceRoleRequest();
		request.setUserId(update.userId);
		request.setRole(update.role);
		return Clients.workspace.updateWorkspaceRole(request, metadata);
	};

	export const createResourceAccount: IGrpcRequestHandlerWithPayload<
		string,
		CreateResourceAccountResponse
	> = (metadata, payload) => {
		const request = new CreateResourceAccountRequest();
		request.setName(payload);
		return Clients.workspace.createResourceAccount(request, metadata);
	};

	export const assignDevice: IGrpcRequestHandlerWithPayload<
		AssignDeviceRequest.AsObject,
		AssignDeviceResponse
	> = (metadata, payload) => {
		const request = new AssignDeviceRequest();
		request.setSerial(payload.serial);
		request.setTargetWorkspaceId(payload.targetWorkspaceId);
		request.setTargetIdentityId(payload.targetIdentityId);
		return Clients.workspace.assignDevice(request, metadata);
	};

	export const removeWorkspaceMembers: IGrpcRequestHandlerWithPayload<
		string[],
		ArchiveMembersResponse
	> = (metadata, payload) => {
		const request = new ArchiveMembersRequest();
		request.setUserIdsList(payload);
		return Clients.workspace.archiveMembers(request, metadata);
	};

	export const addWorkspaceMembers: IGrpcRequestHandlerWithPayload<
		InviteMembersRequest.AsObject,
		InviteMembersResponse
	> = (metadata, payload) => {
		const request = new InviteMembersRequest();
		request.setEmailsList(payload.emailsList);
		request.setIdentityIdsList(payload.identityIdsList);
		request.setInviteToOrganization(payload.inviteToOrganization);
		request.setUsernamesList(payload.usernamesList);
		return Clients.workspace.inviteMembers(request, metadata);
	};

	export const unaryAssignDevice: IGrpcRequestHandlerWithPayload<
		AssignDeviceRequest.AsObject,
		AssignDeviceResponse
	> = (metadata, payload) => {
		const requestObject = payload;
		const request = new AssignDeviceRequest();
		request.setSerial(requestObject.serial);
		request.setTargetWorkspaceId(requestObject.targetWorkspaceId);
		request.setTargetIdentityId(requestObject.targetIdentityId);

		return Clients.device.assignDevice(request, metadata);
	};

	export const bulkAssignDevice: IGrpcRequestHandlerWithPayload<
		AssignDeviceRequest[],
		AssignDeviceResponse.AsObject[]
	> = (metadata, payload) => {
		return firstValueFrom(
			from(payload).pipe(
				mergeMap((request) => {
					return Clients.device.assignDevice(request, metadata);
				}),
				map((it) => it.toObject()),
				toArray(),
			),
		);
	};

	export const updateWorkspaceUser: IGrpcRequestHandlerWithPayload<
		UpdateWorkspaceAccount.AsObject,
		UpdateWorkspaceAccountsResponse
	> = (metadata, payload) => {
		const request = new UpdateWorkspaceAccountList();
		const update = new UpdateWorkspaceAccount();
		update.setId(payload.id);
		update.setFullName(payload.fullName);
		request.addUpdates(update);
		return Clients.user.updateWorkspaceAccounts(request, metadata);
	};

	export const updateWorkspaceUsers: IGrpcRequestHandlerWithPayload<
		UpdateWorkspaceAccountList,
		UpdateWorkspaceAccountsResponse
	> = (metadata, payload) => {
		return Clients.user.updateWorkspaceAccounts(payload, metadata);
	};

	export const joinWorkspaceByKey: IGrpcRequestHandlerWithPayload<
		JoinWorkspaceRequest,
		WorkspaceResponse
	> = (metadata, payload) => {
		return Clients.user.joinWorkspace(payload, metadata);
	};

	export const joinWorkspaceByToken: IGrpcRequestHandlerWithPayload<
		string,
		JoinWorkspaceByInviteResponse
	> = (metadata, payload) => {
		const request = new JoinWorkspaceByInviteRequest();
		request.setToken(payload);
		return Clients.workspace.joinWorkspaceByInvite(request, metadata);
	};

	// MESSAGES

	export const createConversation: IGrpcRequestHandlerWithPayload<
		CreateConversationRequest.AsObject,
		CreateConversationResponse
	> = (metadata, payload) => {
		const cRequest = new CreateConversationRequest();
		cRequest.setTargetUserId(payload.targetUserId);

		const identity = new IdentityRecord();
		if (payload.target?.email !== undefined) identity.setEmail(payload.target.email);
		if (payload.target?.phoneNumber !== undefined)
			identity.setPhoneNumber(payload.target.phoneNumber);
		cRequest.setTarget(identity);

		const mRequest = new MessageRequest();
		if (payload.message?.content !== undefined && payload.message?.timestamp !== undefined) {
			const timestamp = new Timestamp();
			timestamp.setSeconds(payload.message.timestamp.seconds);
			mRequest.setTimestamp(timestamp);
			mRequest.setContent(payload.message.content);
			cRequest.setMessage(mRequest);
		}
		return Clients.messaging.createConversation(cRequest, metadata);
	};

	export const deleteConversation: IGrpcRequestHandlerWithPayload<
		string,
		DeleteConversationResponse
	> = (metadata, payload) => {
		const request = new DeleteConversationRequest();
		request.setConversationId(payload);
		return Clients.messaging.deleteConversation(request, metadata);
	};

	const createMessageRequest = (message: MessageRequestWithState): MessageRequest => {
		const messageRequest = new MessageRequest();
		messageRequest.setContent(message.content);
		messageRequest.setOutgoing(true);
		messageRequest.setConversationId(message.conversationId);
		messageRequest.setWorkspaceId(message.workspaceId);
		return messageRequest;
	};

	export const sendMessage: IGrpcRequestHandlerWithPayload<
		MessageRequestWithStateAndTmpId,
		SendMessageResponse
	> = (metadata, payload) => {
		const request = new SendMessageRequest();
		request.setMessage(createMessageRequest(payload));
		return Clients.messaging.sendMessage(request, metadata);
	};

	export const uploadFile: IGrpcRequestHandlerWithPayload<
		UploadFileRequest,
		GetUploadUrlResponse
	> = (metadata, payload) => {
		const request = new GetUploadUrlRequest();

		request.setMimeType(payload.file.type);
		request.setName(payload.file.name);
		request.setWorkspaceId(payload.workspaceId);
		request.setHashSha256(sha256(payload.file.name).toString());
		request.setFileSize(payload.file.size);

		return Clients.file.getUploadUrl(request, metadata);
	};

	export const downloadFile: IGrpcRequestHandlerWithPayload<
		DownloadFileRequestPayload,
		GetSingleFileUrlResponse
	> = (metadata, payload) => {
		const request = new GetSingleFileUrlRequest();
		request.setFileId(payload.fileId);
		request.setWorkspaceId(payload.workspaceId);

		return Clients.file.getSingleFileUrl(request, metadata);
	};

	export const getWorkspaceFiles: IGrpcRequestHandlerWithPayload<
		GetWorkspaceFilesRequestPayload,
		GetFileMetadataResponse
	> = (metadata, payload) => {
		const request = new GetFileMetadataRequest();
		request.setWorkspaceId(payload.workspaceId);

		return Clients.file.getFiles(request, metadata);
	};

	// WAYPOINTS
	export const fetchWaypoints: IGrpcRequestHandler<GetWaypointsResponse> = (metadata) => {
		return Clients.tracking.getWaypoints(new GetWaypointsRequest(), metadata);
	};

	// DEVICES
	export const fetchDevices: IGrpcRequestHandler<GetDevicesResponse> = (metadata) => {
		return Clients.device.getDevices(new GetIdentityDevicesRequest(), metadata);
	};

	// ASSETS
	export const fetchAssets: IGrpcRequestHandler<GetAssetsResponse> = (metadata) => {
		const request = new GetAssetsRequest();
		request.setIncludeArchived(true);
		return Clients.user.getAssets(request, metadata);
	};

	export const bulkRenameAssets: IGrpcRequestHandlerWithPayload<
		RenameAssetRequest.AsObject[],
		RenameAssetRequest.AsObject[]
	> = (metadata, payload) => {
		return firstValueFrom(
			from(payload).pipe(
				mergeMap((payload) => {
					return renameAsset(metadata, payload);
				}),
				map((it) => it.toObject()),
				toArray(),
			),
		);
	};

	export const renameAsset: IGrpcRequestHandlerWithPayload<
		RenameAssetRequest.AsObject,
		RenameAssetRequest
	> = (metadata, payload) => {
		const request = new RenameAssetRequest();
		request.setIdentityId(payload.identityId);
		request.setFullName(payload.fullName);
		return Clients.user.renameAsset(request, metadata);
	};

	// CAPABILITY
	export const fetchFeatures: IGrpcRequestHandler<GetFeaturesResponse> = (metadata) => {
		return Clients.featureService.getFeatures(new GetFeaturesRequest(), metadata);
	};

	export const fetchCapabilities: IGrpcRequestHandler<GetOrganizationCapabilitiesResponse> = (
		metadata,
	) => {
		return Clients.featureService.getOrganizationCapabilities(
			new GetOrganizationCapabilitiesRequest(),
			metadata,
		);
	};

	// ROUTES
	export const getLiveRoutes: IGrpcRequestHandler<GetRoutesResponse> = (metadata) => {
		return Clients.tracking.getLiveRoutes(new GetLiveRoutesRequest(), metadata);
	};

	export const getLastKnownRoutes: IGrpcRequestHandler<GetRoutesResponse> = (metadata) => {
		return Clients.tracking.getLastKnownRoutes(new GetLastKnownRoutesRequest(), metadata);
	};

	export const getRoutes: IGrpcRequestHandlerWithPayload<GetRoutesRequest, GetRoutesResponse> = (
		metadata,
		payload,
	) => {
		return Clients.tracking.getRoutes(payload, metadata);
	};

	export const fetchDevicesSummary: IGrpcRequestHandler<DeviceSummaryResponseList> = (
		metadata,
	) => {
		return Clients.device.getDevicesSummary(new GetDevicesSummaryRequest(), metadata);
	};

	export const submitDeviceSettings: IGrpcRequestHandlerWithPayload<
		DeviceSettingsResponse.AsObject[],
		DeviceEventResponseList
	> = (metadata, payload) => {
		const requestList = new DeviceSettingsRequestList();
		const requests = payload.mapNotNull((update) => {
			if (update.settings === undefined) return undefined;
			const request = new DeviceSettingsRequest();
			request.setSerial(update.serial);
			const settings = new DeviceSettings();
			settings.setGpsInterval(update.settings.gpsInterval);
			settings.setBatteryReporting(update.settings.batteryReporting);
			settings.setEnableAltitudeReporting(update.settings.enableAltitudeReporting);
			settings.setEnableBackhaul(update.settings.enableBackhaul);
			settings.setTrackingInterval(update.settings.trackingInterval);
			settings.setRadioMode(update.settings.radioMode);
			settings.setButtonFunction(update.settings.buttonFunction);
			settings.setRadioPowerMode(update.settings.radioPowerMode);
			settings.setLowSpeedFrequencyHz(update.settings.lowSpeedFrequencyHz);
			settings.setHighSpeedFrequencyHz(update.settings.highSpeedFrequencyHz);
			settings.setSentTimestamp(moment().unix());
			settings.setTrackingOn(true);
			request.setSettings(settings);
			return request;
		});
		requestList.setRequestsList(requests);
		return Clients.device.updateDevicesSettings(requestList, metadata);
	};

	export const applyQueuedDeviceActivationChanges: IGrpcRequestHandlerWithPayload<
		IQueuedDeviceActivationChange[],
		string[]
	> = (metadata, payload) => {
		return firstValueFrom(
			of(payload).pipe(
				// split the queued activations and suspensions into separate arrays
				map((it) => {
					return {
						activations: it.filter(
							(it) => it.change === DeviceActivationChange.ACTIVATE,
						),
						suspensions: it.filter(
							(it) => it.change === DeviceActivationChange.SUSPEND,
						),
					};
				}),
				// construct request protos for activations and suspensions
				map((it) => {
					return {
						activationRequests: it.activations.map((it) => {
							const request = new ActivateDeviceRequest();
							request.setCurrentState(it.currentState);
							request.setSerial(it.serial);
							return request;
						}),
						suspensionRequests: it.suspensions.map((it) => {
							const request = new SuspendDeviceRequest();
							request.setCurrentState(it.currentState);
							request.setSerial(it.serial);
							return request;
						}),
					};
				}),
				// add request wrappers for bulk activation and suspension requests
				map((it) => {
					const activationRequest = new BatchActivateDevicesRequest();
					activationRequest.setRequestsList(it.activationRequests);

					const suspensionRequest = new BatchSuspendDevicesRequest();
					suspensionRequest.setRequestsList(it.suspensionRequests);

					return {
						activationRequest: activationRequest,
						suspensionRequest: suspensionRequest,
					};
				}),
				// make the bulk activation and suspension requests
				mergeMap((it) =>
					forkJoin({
						activations: Clients.device.batchActivateDevices(
							it.activationRequest,
							metadata,
						),
						suspensions: Clients.device.batchSuspendDevices(
							it.suspensionRequest,
							metadata,
						),
					}),
				),
				// merge the results into a single array of serials
				map((it) => {
					return it.activations
						.toObject()
						.serialsList.concat(it.suspensions.toObject().serialsList);
				}),
			),
		);
	};

	export const suspendDevice: IGrpcRequestHandlerWithPayload<
		IQueuedDeviceActivationChange,
		string
	> = (metadata, payload) => {
		return firstValueFrom(
			of(payload).pipe(
				mergeMap((payload) => {
					const request = new SuspendDeviceRequest();
					request.setCurrentState(payload.currentState);
					request.setSerial(payload.serial);

					const batchRequest = new BatchSuspendDevicesRequest();
					batchRequest.setRequestsList([request]);
					return Clients.device.batchSuspendDevices(batchRequest, metadata);
				}),
				map((it) => it.toObject().serialsList.first()),
			),
		);
	};

	export const getRouteLocations: IGrpcRequestHandlerWithPayload<
		string[],
		GetRouteLocationsResponse
	> = (metadata, payload) => {
		const request = new GetRouteLocationsRequest();
		request.setRouteIdsList(payload);
		return Clients.tracking.getRouteLocations(request, metadata);
	};

	export const joinOrganizationWorkspace: IGrpcRequestHandlerWithPayload<
		string,
		InviteMembersResponse
	> = (metadata, payload) => {
		const request = new JoinOrganizationWorkspaceRequest();
		request.setId(payload);
		return Clients.organization.joinOrganizationWorkspace(request, metadata);
	};

	export const leaveWorkspace: IGrpcRequestHandlerWithPayload<string, ArchiveMembersResponse> = (
		metadata,
		payload,
	) => {
		const request = new LeaveWorkspaceRequest();
		request.setId(payload);
		return Clients.workspace.leaveWorkspace(request, metadata);
	};

	export const configureIntegration: IGrpcRequestHandlerWithPayload<
		IntegrationConfig | SingleWorkspaceIntegrationConfig,
		GetOrganizationIntegrationAccountsResponse
	> = (metadata, payload) => {
		const request = new UpdateOrganizationIntegrationRoutingRequest();
		request.setEnableIntegration(mapToEnableIntegration(payload));
		request.setResourceWorkspaceMappingChangesList(
			mapToUpdateTakResourceAssignedWorkspaces(payload),
		);
		request.setEnableWorkspaceIntegrationsList(mapToEnableIntegrationAccounts(payload));

		return Clients.organization.updateIntegrationRouting(request, metadata);
	};

	export const sendCotToTakServer: IGrpcRequestHandlerWithPayload<
		{ identityId: string; cot: string },
		SendCotToTakServerResponse
	> = (metadata, payload) => {
		const request = new SendCotToTakServerRequest();

		request.setIdentityId(payload.identityId);
		request.setCot(payload.cot);
		return Clients.organization.sendCotToTakServer(request, metadata);
	};

	export const bulkToggleIntegrations: IGrpcRequestHandlerWithPayload<
		ToggleWorkspaceIntegrationRequest.AsObject[],
		UserResponse.AsObject[]
	> = (metadata, payload) => {
		return firstValueFrom(
			from(payload).pipe(
				mergeMap((payload) => {
					return toggleIntegration(metadata, payload);
				}),
				map((it) => it.toObject()),
				toArray(),
			),
		);
	};

	export const toggleIntegration: IGrpcRequestHandlerWithPayload<
		ToggleWorkspaceIntegrationRequest.AsObject,
		UserResponse
	> = (metadata, payload) => {
		const request = new ToggleWorkspaceIntegrationRequest();
		request.setIdentityId(payload.identityId);
		request.setWorkspaceId(payload.workspaceId);
		request.setEnableIntegration(payload.enableIntegration);
		return Clients.workspace.toggleWorkspaceIntegration(request, metadata);
	};

	export const fetchIntegrationAccounts: IGrpcRequestHandler<
		GetOrganizationIntegrationAccountsResponse
	> = (metadata) => {
		return Clients.organization.getIntegrationAccounts(
			new GetOrganizationIntegrationAccountsRequest(),
			metadata,
		);
	};

	export const archiveOrganizationWorkspace: IGrpcRequestHandlerWithPayload<
		string,
		UpdateWorkspaceAccessResponse
	> = (metadata, payload) => {
		const request = new ArchiveWorkspaceRequest();
		request.setId(payload);
		return Clients.workspace.archiveWorkspace(request, metadata);
	};

	export const deleteOrganizationWorkspace: IGrpcRequestHandlerWithPayload<
		string,
		UpdateWorkspaceAccessResponse
	> = (metadata, payload) => {
		const request = new DeleteWorkspaceRequest();
		request.setId(payload);
		return Clients.workspace.deleteWorkspace(request, metadata);
	};

	export const addOrganizationMembers: IGrpcRequestHandlerWithPayload<
		IdentityRecord.AsObject[],
		InviteOrganizationMembersResponse
	> = (metadata, payload) => {
		const request = new InviteOrganizationMembersRequest();
		request.setIdentityRecordsList(
			payload.mapNotNull((item) => {
				const record = new IdentityRecord();
				record.setEmail(item.email ?? "");
				record.setUsername(item.username ?? "");
				record.setId(item.id ?? "");
				record.setOrganizationId(item.organizationId ?? "");
				return record;
			}),
		);
		return Clients.organization.inviteOrganizationMembers(request, metadata);
	};

	export const removeOrganizationMembers: IGrpcRequestHandlerWithPayload<
		IOrganizationAsset[],
		RemoveOrganizationMembersResponse
	> = (metadata, payload) => {
		const request = new RemoveOrganizationMembersRequest();
		request.setIdentityRecordsList(
			payload.mapNotNull((item) => {
				const record = new IdentityRecord();
				record.setId(item.id ?? "");
				return record;
			}),
		);
		return Clients.organization.removeOrganizationMembers(request, metadata);
	};

	const routeResponseFromITrackingRouteWithUser = (
		input: ITrackingRouteWithContact,
	): RouteResponse => {
		const response = new RouteResponse();
		response.setId(input.id);
		response.setName(input.name);
		response.setNotes(input.notes);
		response.setType(input.type);
		const userResponse = new UserResponse();
		userResponse.setId(input.contact.id);
		userResponse.setFullname(input.contact.name);
		response.setOwnerId(input.contact.id);
		response.setOwner(userResponse);
		return response;
	};

	const timestampFromObject = (input: Timestamp.AsObject): Timestamp => {
		const response = new Timestamp();
		response.setSeconds(input.seconds);
		response.setNanos(input.nanos);
		return response;
	};

	const coordinateFromObject = (input: CoordinateDto.AsObject): CoordinateDto => {
		const response = new CoordinateDto();
		response.setLatitude(input.latitude);
		response.setLongitude(input.longitude);
		return response;
	};

	const locationResponseFromObject = (input: LocationResponse.AsObject): LocationResponse => {
		const response = new LocationResponse();
		response.setId(input.id);
		response.setUserId(input.userId);
		response.setAltitude(input.altitude);
		response.setRouteId(input.routeId);
		if (input.coordinate) response.setCoordinate(coordinateFromObject(input.coordinate));
		if (input.timestamp) response.setTimestamp(timestampFromObject(input.timestamp));
		return response;
	};

	export const getSensors: IGrpcRequestHandlerWithPayload<void, GetSensorsResponse> = (
		metadata,
	) => {
		return Clients.sensorService.getSensors(new GetSensorsRequest(), metadata);
	};

	export const downloadRouteLocations: IGrpcRequestHandlerWithPayload<
		{
			routes: ITrackingRouteWithContact[];
			locations: LocationResponse.AsObject[];
		},
		ExportGpxResponse
	> = (metadata, payload) => {
		const request = new ExportGpxRequest();
		request.setRoutesList(
			payload.routes.mapNotNull((route) => routeResponseFromITrackingRouteWithUser(route)),
		);
		request.setLocationsList(
			payload.locations.mapNotNull((location) => locationResponseFromObject(location)),
		);
		return Clients.tracking.exportGpx(request, metadata);
	};

	export const createTrackingLocations: IGrpcRequestHandlerWithPayload<
		GeolocationPosition,
		LocationResponseList
	> = (metadata, payload) => {
		const request = new CreateTrackingLocationsRequest();
		const location = new TrackingLocationDto();
		location.setLatitude(degreeMinuteDtoFromDecimalCoordinate(payload.coords.latitude));
		location.setLongitude(degreeMinuteDtoFromDecimalCoordinate(payload.coords.longitude));
		location.setTimestamp(Math.floor(payload.timestamp / 1000));
		location.setAltitude(payload.coords.altitude ?? 0);
		request.setLocationsList([location]);
		console.log(location.toObject());
		return Clients.tracking.createTrackingLocations(request, metadata);
	};

	export const createWaypoint: IGrpcRequestHandlerWithPayload<
		GeolocationPosition,
		RouteResponse
	> = (metadata, payload) => {
		const request = new CreateWaypointRequest();
		const waypoint = new CreateWaypoint();
		waypoint.setLatitude(degreeMinuteDtoFromDecimalCoordinate(payload.coords.latitude));
		waypoint.setLongitude(degreeMinuteDtoFromDecimalCoordinate(payload.coords.longitude));
		waypoint.setTimestamp(Math.floor(payload.timestamp / 1000));
		waypoint.setName("Waypoint from Web");
		request.setWaypoint(waypoint);

		return Clients.tracking.createWaypoint(request, metadata);
	};

	export const getMostRecentPublicRoute: IGrpcRequestHandlerWithPayload<string, RouteResponse> = (
		metadata,
		payload,
	) => {
		throw Error("unimplemented method");
		/*const request = new GetMostRecentPublicRouteRequest();
		request.setUuid(payload);
		return Clients.tracking.getMostRecentPublicRoute(request, metadata);*/
	};

	export const getPublicRoutes: IGrpcRequestHandlerWithPayload<string, GetRoutesResponse> = (
		metadata,
		payload,
	) => {
		throw Error("unimplemented method");
		/*const request = new GetPublicRoutesRequest();
		request.setUuid(payload);
		return Clients.tracking.getPublicRoutes(request, metadata);*/
	};

	export const getPublicRouteLocations: IGrpcRequestHandlerWithPayload<
		{ uuid: string; routeId: string },
		GetRouteLocationsResponse
	> = (metadata, payload) => {
		throw Error("unimplemented method");
		/*const request = new GetPublicRouteLocationsRequest();
		request.setUuid(payload.uuid);
		const routeRequest = new GetRouteLocationsRequest();
		routeRequest.addRouteIds(payload.routeId);
		request.setRouteRequest(routeRequest);
		return Clients.tracking.getPublicRouteLocations(request, metadata);*/
	};

	export const startSosSession: IGrpcRequestHandlerWithPayload<
		LocationResponse.AsObject,
		SosEventResponse
	> = (metadata, payload) => {
		const message = new SosMessageDto();
		message.setLatitude(degreeMinuteDtoFromDecimalCoordinate(payload.coordinate!.latitude));
		message.setLongitude(degreeMinuteDtoFromDecimalCoordinate(payload.coordinate!.longitude));
		message.setTimestamp(moment(new Date()).unix());
		message.setType(SosMessageDto.SosMessageType.ALARM);
		message.setSandbox(true);
		const request = new SendSosMessageRequest();
		request.setSosMessage(message);
		return Clients.sos.sendSosMessage(request, metadata);
	};

	export const resolveSosSession: IGrpcRequestHandlerWithPayload<void, SosEventResponse> = (
		metadata,
		payload,
	) => {
		const message = new SosMessageDto();
		message.setTimestamp(moment(new Date()).unix());
		message.setType(SosMessageDto.SosMessageType.CANCEL);
		message.setSandbox(true);
		const request = new SendSosMessageRequest();
		request.setSosMessage(message);
		return Clients.sos.sendSosMessage(request, metadata);
	};

	export const saveWaypoint: IGrpcRequestHandlerWithPayload<
		ISaveWaypointPayload,
		RouteResponse
	> = (metadata, payload) => {
		const request = new CreateWaypointRequest();
		const waypoint = new CreateWaypoint();
		waypoint.setLatitude(degreeMinuteDtoFromDecimalCoordinate(payload.location.lat));
		waypoint.setLongitude(degreeMinuteDtoFromDecimalCoordinate(payload.location.lng));
		waypoint.setTimestamp(Math.floor(payload.location.timestamp / 1000));
		waypoint.setName(payload.name);
		if (payload.notes) waypoint.setNotes(payload.notes);

		if (payload.workspaceId !== undefined)
			waypoint.setWorkspaceIdsList([parseInt(payload.workspaceId)]);

		if (payload.cotPackage !== undefined) {
			waypoint.setAffiliation(payload.cotPackage.affiliation);
			waypoint.setDimension(payload.cotPackage.dimension);
			// todo: remove hack to avoid null pointer exception on backend
			if (payload.notes === undefined) waypoint.setNotes("");
		}

		request.setWaypoint(waypoint);

		return Clients.tracking.createWaypoint(request, metadata);
	};

	export const deleteWaypoint: IGrpcRequestHandlerWithPayload<string, DeleteRouteRequest> = (
		metadata,
		payload,
	) => {
		const request = new DeleteRouteRequest();
		request.setRouteId(payload);
		return Clients.tracking.deleteRoute(request, metadata);
	};

	const convertStyleSettingsToProto = (styleSettings: StyleSettings.AsObject): StyleSettings => {
		const style = new StyleSettings();

		if (styleSettings.standardColor !== undefined)
			style.setStandardColor(styleSettings.standardColor);

		if (styleSettings.standardIcon !== undefined)
			style.setStandardIcon(styleSettings.standardIcon);

		if (styleSettings.affiliation !== undefined)
			style.setAffiliation(styleSettings.affiliation);
		if (styleSettings.dimension !== undefined) style.setDimension(styleSettings.dimension);
		return style;
	};

	export const updateIdentityStyle: IGrpcRequestHandlerWithPayload<
		UpdateStyleSettingsRequest.AsObject,
		UserIdentityResponse
	> = (metadata, payload) => {
		const request = new UpdateStyleSettingsRequest();
		request.setEntityId(payload.entityId);
		if (payload.styleSettings !== undefined) {
			const styles = convertStyleSettingsToProto(payload.styleSettings);
			request.setStyleSettings(styles);
		}
		return Clients.user.updateStyle(request, metadata);
	};

	export const updateWorkspaceStyle: IGrpcRequestHandlerWithPayload<
		UpdateStyleSettingsRequest.AsObject,
		WorkspaceResponse
	> = (metadata, payload) => {
		const request = new UpdateStyleSettingsRequest();
		request.setEntityId(payload.entityId);
		if (payload.styleSettings !== undefined) {
			const styles = convertStyleSettingsToProto(payload.styleSettings);
			request.setStyleSettings(styles);
		}
		return Clients.workspace.updateStyle(request, metadata);
	};

	// SHAPES
	export const getShapes: IGrpcRequestHandler<GetShapesResponse.AsObject> = (metadata) => {
		return firstValueFrom(
			of(metadata).pipe(
				mergeMap((metadata) => {
					return Clients.shape.getShapes(new GetShapesRequest(), metadata);
				}),
				map((response) => {
					const responseObject = response.toObject();
					responseObject.shapesList.forEach((shape) => {
						if (shape?.circle !== undefined) {
							shape.circle.radius /= 1000; // convert radius to kms
						}
					});
					return responseObject;
				}),
			),
		);
	};

	export const editShape: IGrpcRequestHandlerWithPayload<Shape.AsObject, UpdateShapeResponse> = (
		metadata,
		payload,
	) => {
		const request = new UpdateShapeRequest();
		request.assignShape(payload);
		return Clients.shape.updateShape(request, metadata);
	};

	export const createShape: IGrpcRequestHandlerWithPayload<
		Shape.AsObject,
		CreateShapeResponse.AsObject
	> = (metadata, payload) => {
		return firstValueFrom(
			of({ payload: payload, metadata: metadata }).pipe(
				mergeMap((input) => {
					const { payload, metadata } = input;
					const request = new CreateShapeRequest();
					request.assignShape(payload);
					return Clients.shape.createShape(request, metadata);
				}),
				map((response) => {
					const responseObject = response.toObject();
					if (responseObject.shape?.circle !== undefined) {
						responseObject.shape.circle.radius /= 1000; // convert radius to kms
					}
					return responseObject;
				}),
			),
		);
	};

	export const deleteShape: IGrpcRequestHandlerWithPayload<string, DeleteShapeResponse> = (
		metadata,
		payload,
	) => {
		const request = new DeleteShapeRequest();
		request.setId(payload);
		return Clients.shape.deleteShape(request, metadata);
	};

	export const confirmOrganizationClaimCode: IGrpcRequestHandlerWithPayload<
		string,
		ConfirmOrganizationClaimCodeResponse
	> = (metadata, payload) => {
		const request = new ConfirmOrganizationClaimCodeRequest();
		request.setOrganizationClaimCode(payload);
		return Clients.organization.confirmClaimCode(request, metadata);
	};

	export const claimOrganization: IGrpcRequestHandlerWithPayload<
		ClaimOrganizationRequest.AsObject,
		OrganizationRecord
	> = (metadata, payload) => {
		const request = new ClaimOrganizationRequest();
		request.setOrganizationClaimCode(payload.organizationClaimCode);
		request.setOrganizationName(payload.organizationName);
		return Clients.organization.claimOrganization(request, metadata);
	};

	// STREAM

	class NotificationStreamManager extends StreamManager<NotificationPayload> {
		constructor() {
			super((metadata) => {
				return new StreamData(
					Clients.notification.getNotificationStream(
						new GetNotificationStreamRequest(),
						metadata,
					) as ClientReadableStream<NotificationPayload>,
				);
			});
		}
	}
	export const notificationStreamManager: NotificationStreamManager =
		new NotificationStreamManager();
}

export { SomewearGrpc as someGrpc };

export default SomewearGrpc;
